/* eslint-disable react/prop-types */
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import NodeContainer, {
	MenuWrapper,
	NodeContentWrapper,
	NodeLabel,
	Text,
	TextMultiLine,
	useStyles,
} from './InnoInputNode.styles';
import {
	Add as AddIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
} from '@mui/icons-material';
import { getSubscriptions, rem } from '@innovyze/stylovyze';
import { fullAccess } from '@innovyze/shared-utils';
import { useGlobalization } from '@Translations';

interface InnoInputNodeProps {
	data: {
		id: string;
		name: string;
		title: string;
		text: string;
		onEditClick: (id: string) => void;
		onDeleteClick: (id: string) => void;
		onDuplicateClick: (id: string) => void;
		data: {
			label: string;
			// nodeData: [] rules, title, etc
		};
	};
}

const InnoInputNode = (props: InnoInputNodeProps) => {
	const { t } = useGlobalization();
	const subscriptions = getSubscriptions();
	const hasFullAccess = fullAccess(subscriptions);

	const classes = useStyles();
	const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const { id, title, onEditClick, onDeleteClick, onDuplicateClick } =
		props.data;

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setMenuAnchorEl(null);
	};

	const handleEditClick = () => {
		onEditClick(id);
		setMenuAnchorEl(null);
	};

	const handleDeleteClick = () => {
		onDeleteClick(id);
		setMenuAnchorEl(null);
	};

	const handleDuplicateClick = () => {
		onDuplicateClick(id);
		setMenuAnchorEl(null);
	};

	return (
		<NodeContainer
			style={
				title.length > 25
					? { width: rem(300), height: rem(72) }
					: { width: rem(200), height: rem(48) }
			}>
			<NodeLabel>{t('Query')}</NodeLabel>
			{hasFullAccess ? (
				<MenuWrapper>
					<IconButton
						data-cy="threeDotMenu"
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						size="small"
						onClick={handleMenuClick}>
						<MoreVert />
					</IconButton>
					<Menu
						id="create-node-menu"
						anchorEl={menuAnchorEl}
						open={Boolean(menuAnchorEl)}
						onClose={handleMenuClose}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
						<MenuItem
							className={classes.nodeMenuItem}
							onClick={handleEditClick}
							data-cy="editOption">
							<EditIcon color="action" fontSize="small" />
							<span>{t('Edit')}</span>
						</MenuItem>
						<MenuItem
							className={classes.nodeMenuItem}
							onClick={handleDuplicateClick}
							data-cy="duplicateOption">
							<AddIcon color="action" fontSize="small" />
							<span>{t('Duplicate')}</span>
						</MenuItem>
						<MenuItem
							className={classes.nodeMenuItem}
							onClick={handleDeleteClick}
							data-cy="deleteOption">
							<DeleteIcon color="action" fontSize="small" />
							<span>{t('Delete')}</span>
						</MenuItem>
					</Menu>
				</MenuWrapper>
			) : (
				<> </>
			)}
			<NodeContentWrapper>
				{title.length > 25 ? (
					<TextMultiLine> {title} </TextMultiLine>
				) : (
					<Text>{title}</Text>
				)}
			</NodeContentWrapper>
			<Handle
				type="source"
				position={Position.Bottom}
				isConnectable={hasFullAccess}
			/>
			<Handle
				type="target"
				position={Position.Top}
				isConnectable={hasFullAccess}
			/>
		</NodeContainer>
	);
};

export default InnoInputNode;
