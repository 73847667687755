import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Rules } from '@Types/rehab.types';
import { useStyles } from '../RulesList.styles';

import { Button } from '@mui/material';
import { showRuleValues } from '../RulesList.component';
import { DragIndicator } from '@mui/icons-material';
import { selectAllRehabRuleChoiceListFiles } from '@Selectors/ChoiceList.selectors';
import RowMenu from '../RowMenu';

interface Props {
	rule: Rules;
	groupedRule: Rules;
	classes?: any;
	ruleIdToCreateGrouped: string;
	updateRuleCondition: (rule: Rules) => void;
	updateGroupRuleCondition: (rule: Rules, groupedRule: Rules) => void;
	setRuleIdToCreateGrouped: (id: string) => void;
	onEditRule: (id: string, groupedId: string | null) => void;
	onDeleteRule: (id: string, groupedId: string | null) => void;
	handleConditionClickedForGroupedRule: (
		condition: string,
		ruleId: string,
	) => void;
	setQueryPanelDisabledState: (state: boolean) => void;
	isQueryPanelDisabled: boolean;
}

export const SortableGroupedRuleItem = SortableElement(
	({
		rule,
		groupedRule,
		updateGroupRuleCondition,
		onEditRule,
		onDeleteRule,
		setQueryPanelDisabledState,
		isQueryPanelDisabled,
	}: Props) => {
		const classes = useStyles();
		const recordTypeChoiceListFiles = selectAllRehabRuleChoiceListFiles();
		const groupRuleConnectorClass = `${classes.ruleConditionConnector} ${classes.groupedRuleConditionConnector}`;

		return (
			<div
				id="sortable_grouped-rule"
				className={classes.groupedRuleItem}
				style={{
					display: 'flex',
					zIndex: 2000,
				}}>
				{rule.groupedRules && (
					<div>
						<Button
							className={groupRuleConnectorClass}
							variant="contained"
							onClick={() =>
								updateGroupRuleCondition(rule, groupedRule)
							}
							disabled={isQueryPanelDisabled}>
							{groupedRule.ruleCondition}
						</Button>
					</div>
				)}
				{showRuleValues(
					recordTypeChoiceListFiles,
					groupedRule,
					classes,
					<DragIndicator
						id="sortable_groupedRule_icon"
						className={
							isQueryPanelDisabled
								? classes.ruleDragIconDisabled
								: classes.ruleDragIcon
						}
						color="disabled"
						fontSize="small"
					/>,
				)}
				<RowMenu
					id={rule.id}
					groupedId={groupedRule.id}
					onEditRule={onEditRule}
					onDeleteRule={onDeleteRule}
					classes={classes}
					setQueryPanelDisabledState={setQueryPanelDisabledState}
					isQueryPanelDisabled={isQueryPanelDisabled}
				/>
			</div>
		);
	},
);
