import { Timeline } from '@mui/lab';
import { Rules } from '@Types/rehab.types';
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { SortableRuleItem } from './SortableRuleItem';
interface Props {
	rules: Rules[];
	classes: any;
	ruleIdToCreateGrouped: string;
	updateRuleCondition: (rule: Rules) => void;
	updateGroupRuleCondition: (rule: Rules, groupedRule: Rules) => void;
	setRuleIdToCreateGrouped: (id: string) => void;
	setShowRuleCondition: (show: boolean) => void;
	onEditRule: (id: string, groupedId: string | null) => void;
	onDeleteRule: (id: string, groupedId: string | null) => void;
	handleConditionClickedForGroupedRule: (
		condition: string,
		ruleId: string,
	) => void;
	setRules: (rules: Rules[]) => void;
	setQueryPanelDisabledState: (state: boolean) => void;
	isQueryPanelDisabled: boolean;
	isFilter: boolean;
}
export const SortableRuleList = SortableContainer(
	({
		rules,
		classes,
		ruleIdToCreateGrouped,
		updateRuleCondition,
		updateGroupRuleCondition,
		setRuleIdToCreateGrouped,
		setShowRuleCondition,
		onEditRule,
		onDeleteRule,
		handleConditionClickedForGroupedRule,
		setRules,
		setQueryPanelDisabledState,
		isQueryPanelDisabled,
		isFilter,
	}: Props) => {
		return (
			<Timeline
				style={{
					paddingLeft: '11px',
					marginBottom: '0',
					marginTop: '0',
					paddingTop: '0',
				}}>
				{rules?.map((rule: Rules, index: number) => (
					<SortableRuleItem
						key={`item-${rule.id}`}
						index={index}
						rule={rule}
						sortIndex={index}
						classes={classes}
						ruleIdToCreateGrouped={ruleIdToCreateGrouped}
						updateRuleCondition={updateRuleCondition}
						updateGroupRuleCondition={updateGroupRuleCondition}
						setRuleIdToCreateGrouped={setRuleIdToCreateGrouped}
						setShowRuleCondition={setShowRuleCondition}
						onEditRule={onEditRule}
						onDeleteRule={onDeleteRule}
						handleConditionClickedForGroupedRule={
							handleConditionClickedForGroupedRule
						}
						setRules={setRules}
						rules={rules}
						setQueryPanelDisabledState={setQueryPanelDisabledState}
						isQueryPanelDisabled={isQueryPanelDisabled}
						isFilter={isFilter}
					/>
				))}
			</Timeline>
		);
	},
);

export default SortableRuleList;
