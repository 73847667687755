import { useIsFeatureEnabled } from '@innovyze/stylovyze';
import React, { ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './init';

interface GlobalizationProviderProps {
	children: ReactNode;
}

export const GlobalizationProvider = ({
	children,
}: GlobalizationProviderProps) => {
	const locale = useIsFeatureEnabled('info-360-locale');
	if (locale) {
		i18n.changeLanguage(locale as unknown as string);
	}
	return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
