import {
	rehabReducer as RehabStore,
	initState as rehabInitState,
} from './Rehab.reducer';

import {
	choiceListReducer as ChoiceListStore,
	initState as choiceListInitState,
} from './ChoiceList.reducer';

import { StoreState } from '@Types';
import { combineReducers } from 'redux';
import { moduleNamespace } from '@Store/config.store';

export const initialState: StoreState = {
	RehabStore: rehabInitState,
	ChoiceListStore: choiceListInitState,
};

export default combineReducers({
	RehabStore,
	ChoiceListStore,
});

export const reducerKey = moduleNamespace;
