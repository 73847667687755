import { Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { rem } from '@Utils/styles';
import styled from 'styled-components';

const NodeContainer = styled.div`
	background: #ffffff;
	border: 2px solid #007ca0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.625rem;
	border-radius: 0.25rem;
	width: ${rem(200)};
	height: ${rem(48)};
	box-shadow: none !important;
	white-space: pre-line;
`;

export const NodeLabel = styled.div`
	font-size: ${rem(12)};
	padding: 0 ${rem(4)};
	position: absolute;
	top: ${rem(-8)};
	left: ${rem(12)};
	color: #007ca0;
	background: #ffffff;
	border-radius: ${rem(3)};
`;

export const MenuWrapper = styled.div`
	position: absolute;
	top: ${rem(8)};
	right: ${rem(8)};
`;

export const NodeContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Title = styled(Typography)`
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	white-space: pre;
	font-size: ${rem(16)};
	line-height: ${rem(22)};
`;

export const Text = styled(Typography)`
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	white-space: pre;
	font-size: ${rem(12)};
	line-height: ${rem(16)};
	color: #768d95;
	padding-right: ${rem(18)};
`;

export const TextMultiLine = styled(Typography)`
	text-align: center;
	white-space: normal;
	word-break: breakWord;
	font-size: ${rem(12)};
	line-height: ${rem(24)};
	color: #768d95;
	padding-right: ${rem(18)};
`;

export const useStyles = makeStyles(() =>
	createStyles({
		nodeMenuItem: {
			fontSize: '12px',
			padding: '3px 5px',
			'& > span': {
				color: '#4A6067',
				paddingLeft: '5px',
			},
		},
	}),
);
export default NodeContainer;
