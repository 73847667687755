import {
	createRehabTree,
	createNewRehabTree,
	addNewNode,
	addNewEdge,
	getRehabDetails,
	setTab,
	getRehabTrees,
	getRehabTreesResolved,
	getRehabTreesRejected,
	setCurrentTreeId,
	getRehabTreeResolved,
	addNodeToNewTree,
	setNewTreeName,
	setNodeCondition,
	setNodePosition,
	addRehabTreeResolved,
	deleteRehabTreeResolved,
	setNewTree,
	setRehabTreesLoaded,
	getRehabActions,
	getRehabActionsResolved,
	getRehabActionsRejected,
	addRehabAction,
	addRehabActionResolved,
	addRehabActionRejected,
	saveRehabAction,
	saveRehabActionResolved,
	saveRehabActionRejected,
	deleteRehabActionResolved,
	deleteRehabActionRejected,
	deleteRehabAction,
	validateRehabTree,
	validateRehabTreeResolved,
	validateRehabTreeRejected,
	runRehabTree,
	runRehabTreeResolved,
	runRehabTreeRejected,
	getRehabResultResolved,
	getRehabResultRejected,
	changePage,
	changeSearch,
	changeSort,
	clearSelected,
	setSelected,
	addSelected,
	removeSelected,
	setRehabResultIsBusy,
	currentRow,
	changeFilterSelect,
	updateColumnFilterSelectOptions,
	translate,
	addRehabTree,
	addRehabTreeRejected,
	saveRehabTree,
	getRehabTree,
	deleteRehabTree,
	deleteRehabTreeRejected,
	addRehabTreeValidationResult,
	updateRehabTreeStatus,
	resetFilters,
	duplicateRehabTree,
	duplicateRehabTreeResolved,
	duplicateRehabTreeRejected,
	setStartNodeId,
	getRehabCost,
	getRehabCostResolved,
	getRehabCostRejected,
	clearRehabTrees,
	changeRehabTreesPage,
	changeRehabTreesSlice,
	changeRehabTreesSort,
	setRehabTreesIsBusy,
	getRehabTreesListRejected,
	getRehabTreesListResolved,
	getRehabTreesList,
	setRehabTreeNameError,
	setRefreshList,
	setRehabTreesIsRenaming,
	setAllRiskConfigModels,
	clearCurrentTree,
	addNewRehabActionToTree,
	getRehabTreeStatus,
	getRehabTreeStatusResolved,
	getRehabTreeValidationStatus,
	getRehabTreeValidationStatusResolved,
	changePagination,
	changeFiltersSelect,
	clearScanId,
} from '@Actions';
import { createReducer } from '@reduxjs/toolkit';
import { RehabStoreState, TreeNode } from '@Types';
import { AnyAction } from 'redux';
import * as AM_COMMON from '@innovyze/lib_am_common/Reducers';
import { rehabResultInitialState } from './filterInitialState';
import { ColumnFilterValue, Pagination } from '@innovyze/lib_am_common';

export const initState: RehabStoreState = {
	isLoading: false,
	isCreating: false,
	isDeleting: false,
	isRenaming: false,
	rehabTreeNameError: '',
	refreshList: false,
	isCostLoading: false,
	tab: '',
	rehabDetails: [],
	newTree: {
		_id: '',
		name: '',
		treeNodes: [],
		edges: [],
	},
	saveRun: false,
	// isNewTree: false,
	newDecisionTree: {
		name: '',
		systemType: '',
		assetType: '',
		treeNodes: [],
	},
	reactFlowDecisionTrees: [],
	currentTreeId: '',
	fullRehabTree: {
		_id: '',
		name: '',
		thumbnail: '',
		nodes: [],
		inspectionStandard: '',
		status: '',
	},
	connectorObject: {
		id: '',
		connectorCondition: false,
	},
	rehabActions: [],
	rehabResult: AM_COMMON.paginationInitialState,
	dropdownFilters: rehabResultInitialState(),
	search: '',
	onlyShowLocked: false,
	rehabTreeValidation: {
		treeId: '',
		isValid: true,
		validationMessages: [],
	},
	startNodeId: '',
	rehabCost: { ASSET_ID: '' },
	rehabTrees: AM_COMMON.paginationInitialState,
	rehabTreesList: [],
	isRehabTreesListLoading: false,
	allRiskConfigModels: [],
};
interface RehabReducer {
	[x: string]: (
		state: RehabStoreState,
		action: AnyAction,
	) => RehabStoreState | undefined;
}

export const reducer: RehabReducer = {
	[setTab.toString()]: (state, action) => ({
		...state,
		tab: action.payload,
	}),
	[setRehabTreesLoaded.toString()]: state => ({
		...state,
		isLoading: false,
	}),
	[getRehabDetails.toString()]: (state, action) => ({
		...state,
		rehabDetails: AM_COMMON.updateGet(state.rehabDetails, action.payload),
	}),
	[addNewNode.toString()]: (state, action) => ({
		...state,
		newTree: {
			_id: state.newTree._id,
			name: state.newTree.name,
			treeNodes: [...state.newTree.treeNodes, action.payload],
			edges: state.newTree.edges,
		},
	}),
	[addNewEdge.toString()]: (state, action) => ({
		...state,
		newTree: {
			_id: state.newTree._id,
			name: state.newTree.name,
			treeNodes: state.newTree.treeNodes,
			edges: [...state.newTree.edges, action.payload],
		},
	}),
	[createRehabTree.toString()]: (state, action) => ({
		...state,
		newTree: {
			_id: state.newTree._id,
			name: state.newTree.name,
			treeNodes: state.newTree.treeNodes,
			edges: [...state.newTree.edges, action.payload],
		},
	}),
	[createNewRehabTree.toString()]: (state, action) => {
		const newTree: TreeNode = {
			_id: action.payload,
			name: action.payload,
			treeNodes: state.newTree.treeNodes,
			edges: state.newTree.edges,
		};
		return {
			...state,
			rehabDetails: [...state.rehabDetails, newTree],
		};
	},
	[getRehabTree.toString()]: state => ({
		...state,
		isLoading: true,
	}),
	[setCurrentTreeId.toString()]: (state, action) => ({
		...state,
		//isLoading:false,
		currentTreeId: action.payload,
	}),
	[getRehabTreeResolved.toString()]: (state, action) => ({
		...state,
		fullRehabTree: action.payload,
	}),
	[addNodeToNewTree.toString()]: (state, action) => {
		return {
			...state,
			newDecisionTree: {
				...state.newDecisionTree,
				treeNodes: [...state.newDecisionTree.treeNodes, action.payload],
			},
		};
	},
	[setNewTreeName.toString()]: (state, action) => ({
		...state,
		newDecisionTree: {
			name: action.payload.name,
			assetType: action.payload.assetType,
			systemType: action.payload.systemType,
			treeNodes: state.newDecisionTree.treeNodes,
			inspectionStandard: action.payload.inspectionStandard,
		},
	}),
	[setNewTree.toString()]: state => ({
		...state,
		fullRehabTree: [],
		newDecisionTree: {
			name: '',
			systemType: '',
			assetType: '',
			treeNodes: [],
			inspectionStandard: '',
		},
		currentTreeId: '',
	}),
	[setNodeCondition.toString()]: (state, action) => ({
		...state,
		connectorObject: action.payload,
	}),
	[setNodePosition.toString()]: (state, action) => ({
		...state,
		newDecisionTree: {
			...state.newDecisionTree,
			treeNodes: state.newDecisionTree.treeNodes.map(node =>
				node.id === action.payload.Id
					? {
							...node,
							position: action.payload.position,
					  }
					: node,
			),
		},
	}),
	[saveRehabTree.toString()]: state => ({
		...state,
		isLoading: true,
	}),
	[addRehabTree.toString()]: state => ({
		...state,
		isLoading: true,
	}),
	[addRehabTreeResolved.toString()]: state => ({
		...state,
		isLoading: false,
		// isNewTree: false,
		newDecisionTree: {
			...state.newDecisionTree,
			treeNodes: [],
		},
	}),
	[addRehabTreeRejected.toString()]: state => ({
		...state,
		isLoading: false,
		newDecisionTree: {
			...state.newDecisionTree,
			treeNodes: [],
		},
	}),
	[deleteRehabTree.toString()]: state => ({
		...state,
		isDeleting: true,
	}),
	[deleteRehabTreeResolved.toString()]: (state, action) => {
		return {
			...state,
			isDeleting: false,
			reactFlowDecisionTrees: state.reactFlowDecisionTrees.filter(
				tree => action.payload.indexOf(tree._id) < 0,
			),
		};
	},
	[deleteRehabTreeRejected.toString()]: state => ({
		...state,
		isDeleting: false,
	}),

	[getRehabActions.toString()]: state => ({
		...state,
		isLoading: true,
	}),
	[getRehabActionsResolved.toString()]: (state, action) => ({
		...state,
		rehabActions: action.payload,
		isLoading: false,
	}),
	[getRehabActionsRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),

	[addRehabAction.toString()]: state => ({
		...state,
		isLoading: true,
	}),

	[addRehabActionResolved.toString()]: (state, action) => ({
		...state,
		rehabActions: [...state.rehabActions, action.payload],
		isLoading: false,
	}),
	[addRehabActionRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),

	[addNewRehabActionToTree.toString()]: (state, action) => ({
		...state,
		rehabActionToAdd: action.payload,
	}),

	[saveRehabAction.toString()]: state => ({
		...state,
		isLoading: true,
	}),

	[saveRehabActionResolved.toString()]: (state, action) => ({
		...state,
		rehabActions: state.rehabActions.map(rehabAction => {
			if (rehabAction._id == action.payload._id) {
				return action.payload;
			} else {
				return rehabAction;
			}
		}),
		isLoading: false,
	}),
	[saveRehabActionRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),

	[deleteRehabAction.toString()]: state => ({
		...state,
		isLoading: true,
	}),

	[deleteRehabActionResolved.toString()]: (state, action) => ({
		...state,
		rehabActions: state.rehabActions?.filter(
			rehabAction => action.payload != rehabAction._id,
		),
		isLoading: false,
	}),

	[deleteRehabActionRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),

	[validateRehabTree.toString()]: state => ({
		...state,
		isLoading: true,
	}),

	[validateRehabTreeResolved.toString()]: state => ({
		...state,
		isLoading: false,
	}),

	[validateRehabTreeRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),

	[runRehabTree.toString()]: state => ({
		...state,
		isLoading: true,
	}),

	[runRehabTreeResolved.toString()]: state => ({
		...state,
		isLoading: false,
	}),

	[runRehabTreeRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),
	[getRehabResultResolved.toString()]: (state, action) => ({
		...state,
		rehabResult: AM_COMMON.updateResolved(
			state.rehabResult,
			action.payload,
		),
	}),
	[getRehabResultRejected.toString()]: (state, action) => ({
		...state,
		rehabResult: AM_COMMON.updateRejected(
			state.rehabResult,
			action.payload,
		),
	}),
	[clearScanId.toString()]: state => ({
		...state,
		rehabResult: { ...state.rehabResult, scanId: '' },
	}),
	[changePage.toString()]: (state, action) => ({
		...state,
		rehabResult: AM_COMMON.updatePage(state.rehabResult, action.payload),
	}),
	[changePagination.toString()]: (state, action) => {
		const query: Pagination = AM_COMMON.makePaginationQuery({
			page: action.payload?.page,
			slice: action.payload?.slice,
			pages: state.rehabResult?.pagination?.pages,
			total: state.rehabResult.pagination.total,
			subtotal: state.rehabResult.pagination.subtotal,
			search: state.rehabResult.pagination.search,
			sortKey: state.rehabResult.pagination.sortKey,
			sortDescending: state.rehabResult.pagination.sortDescending,
		});

		const pagination = {
			...state.rehabResult.pagination,
			slice:
				action.payload?.slice ?? state.rehabResult?.pagination?.slice,
			page: action.payload?.page ?? state.rehabResult?.pagination?.page,
		};

		return {
			...state,
			rehabResult: {
				...state.rehabResult,
				isWaiting: true,
				status: AM_COMMON.statusLoading(),
				pagination,
				query,
			},
		};
	},

	[changeSearch.toString()]: (state, action) => ({
		...state,
		search: action.payload,
		rehabResult: AM_COMMON.updateSearch(state.rehabResult, action.payload),
	}),
	[changeSort.toString()]: (state, action) => ({
		...state,
		rehabResult: AM_COMMON.updateSort(state.rehabResult, action.payload),
	}),
	[clearSelected.toString()]: state => ({
		...state,
		rehabResult: AM_COMMON.setSelected(state.rehabResult, []),
	}),
	[setSelected.toString()]: (state, action) => ({
		...state,
		rehabResult: AM_COMMON.setSelected(state.rehabResult, action.payload),
	}),
	[addSelected.toString()]: (state, action) => ({
		...state,
		rehabResult: AM_COMMON.addSelected(state.rehabResult, action.payload),
	}),
	[removeSelected.toString()]: (state, action) => ({
		...state,
		rehabResult: AM_COMMON.removeSelected(
			state.rehabResult,
			action.payload,
		),
	}),
	[setRehabResultIsBusy.toString()]: (state, action) => ({
		...state,
		rehabResult: AM_COMMON.updateIsBusy(state.rehabResult, action.payload),
	}),
	[currentRow.toString()]: (state, action) => ({
		...state,
		rehabResult: AM_COMMON.setCurrentDbId(
			state.rehabResult,
			action.payload,
		),
	}),
	[changeFilterSelect.toString()]: (state, action) => ({
		...state,
		dropdownFilters: AM_COMMON.updateChangeFilterSelect(
			state.dropdownFilters,
			action.payload,
		),
	}),
	[changeFiltersSelect.toString()]: (state, action) => {
		const columns = (action.payload ?? [])?.filter(
			(column: ColumnFilterValue) => column,
		);

		return {
			...state,
			dropdownFilters: AM_COMMON.changeMultipleFiltersSelect(
				state.dropdownFilters,
				columns,
			),
		};
	},
	[resetFilters.toString()]: state => ({
		...state,
		dropdownFilters: rehabResultInitialState(),
		search: '',
		onlyShowLocked: false,
		rehabResult: AM_COMMON.paginationInitialState,
	}),

	[updateColumnFilterSelectOptions.toString()]: (state, action) => ({
		...state,
		dropdownFilters: AM_COMMON.updateColumnFilterSelectOptions(
			state.dropdownFilters,
			action.payload,
		),
	}),
	[translate.toString()]: (state, action) => ({
		...state,
		dropdownFilters: AM_COMMON.updateTranslations(
			state.dropdownFilters,
			action.payload,
		),
	}),

	[addRehabTreeValidationResult.toString()]: (state, action) => ({
		...state,
		rehabTreeValidation: {
			treeId: action.payload.treeId,
			isValid: action.payload.isValid,
			validationMessages: action.payload.validationMessages,
		},
	}),

	[updateRehabTreeStatus.toString()]: state => ({
		...state,
	}),

	[duplicateRehabTree.toString()]: state => ({
		...state,
		isCreating: true,
	}),

	[duplicateRehabTreeResolved.toString()]: state => ({
		...state,
		isCreating: false,
	}),

	[duplicateRehabTreeRejected.toString()]: state => ({
		...state,
		isCreating: false,
	}),
	[setStartNodeId.toString()]: (state, action) => ({
		...state,
		//isLoading:false,
		startNodeId: action.payload,
	}),

	[getRehabCost.toString()]: state => ({
		...state,
		isCostLoading: true,
	}),

	[getRehabCostResolved.toString()]: (state, action) => ({
		...state,
		rehabCost: action.payload,
		isCostLoading: false,
	}),

	[getRehabCostRejected.toString()]: state => ({
		...state,
		rehabCost: null,
		isCostLoading: false,
	}),

	[getRehabTrees.toString()]: (state, action) => ({
		...state,
		rehabTrees: AM_COMMON.updateGet(state.rehabTrees, action.payload),
	}),
	[clearRehabTrees.toString()]: (state, action) => ({
		...state,
		rehabTrees: AM_COMMON.clearPagination(state.rehabTrees, action.payload),
	}),
	[getRehabTreesResolved.toString()]: (state, action) => ({
		...state,
		rehabTrees: AM_COMMON.updateResolvedCard(
			state.rehabTrees,
			action.payload,
		),
	}),
	[getRehabTreesRejected.toString()]: (state, action) => ({
		...state,
		rehabTrees: AM_COMMON.updateRejected(state.rehabTrees, action.payload),
	}),
	[changeRehabTreesPage.toString()]: (state, action) => ({
		...state,
		rehabTrees: AM_COMMON.updatePage(state.rehabTrees, action.payload),
	}),
	[changeRehabTreesSlice.toString()]: (state, action) => ({
		...state,
		rehabTrees: AM_COMMON.updateSlice(state.rehabTrees, action.payload),
	}),
	[changeRehabTreesSort.toString()]: (state, action) => ({
		...state,
		rehabTrees: AM_COMMON.updateSort(state.rehabTrees, action.payload),
	}),
	[setRehabTreesIsBusy.toString()]: (state, action) => ({
		...state,
		rehabTrees: AM_COMMON.updateIsBusyCards(
			state.rehabTrees,
			action.payload,
		),
	}),
	[getRehabTreesList.toString()]: state => ({
		...state,
		rehabTreesList: [],
		isRehabTreesListLoading: true,
	}),
	[getRehabTreesListResolved.toString()]: (state, action) => ({
		...state,
		rehabTreesList: action.payload,
		isRehabTreesListLoading: false,
	}),
	[getRehabTreesListRejected.toString()]: state => ({
		...state,
		isRehabTreesListLoading: false,
	}),
	[setRehabTreeNameError.toString()]: (state, action) => ({
		...state,
		rehabTreeNameError: action.payload,
	}),
	[setRefreshList.toString()]: (state, action) => ({
		...state,
		refreshList: action.payload,
	}),
	[setRehabTreesIsRenaming.toString()]: (state, action) => ({
		...state,
		isRenaming: action.payload,
	}),
	[setAllRiskConfigModels.toString()]: (state, action) => ({
		...state,
		//isLoading:false,
		allRiskConfigModels: action.payload,
	}),
	[clearCurrentTree.toString()]: state => ({
		...state,
		fullRehabTree: {
			_id: '',
			name: '',
			thumbnail: '',
			nodes: [],
		},
	}),
	[getRehabTreeStatus.toString()]: state => state,
	[getRehabTreeStatusResolved.toString()]: (state, action) => ({
		...state,
		fullRehabTree: {
			...state.fullRehabTree,
			status: action.payload,
		},
	}),
	[getRehabTreeValidationStatus.toString()]: state => state,
	[getRehabTreeValidationStatusResolved.toString()]: (state, action) => ({
		...state,
		fullRehabTree: {
			...state.fullRehabTree,
			validationStatus: action.payload,
		},
	}),
};

export const rehabReducer = createReducer(initState, reducer);
