export enum RECORD_TYPE {
	ASSET_ATTRIBUTES = 'Asset attributes',
	INSPECTION = 'Inspection',
	RISK = 'Risk',
	CONDITION = 'Condition',
	USER_DEFINED_FIELDS = 'Custom Table',
	DEFECT_FILTER = 'Defect Filter',
	TASK = 'Task',
}

export const recordTypeList: string[] = [
	RECORD_TYPE.ASSET_ATTRIBUTES,
	RECORD_TYPE.INSPECTION,
	RECORD_TYPE.RISK,
	RECORD_TYPE.CONDITION,
	RECORD_TYPE.USER_DEFINED_FIELDS,
	RECORD_TYPE.DEFECT_FILTER,
	RECORD_TYPE.TASK,
];

export enum FIELDS_TYPES {
	TEXT_ONE_FIELD = 'text',
	MULTI_SELECT_ONE_FIELD = 'multi-select',
	NUMERICAL_ONE_FIELD = 'numeric-text',
	NUMERICAL_TWO_FIELDS = 'numeric-text-two-fields',
	CURRENCY_ONE_FIELD = 'currency-text',
	CURRENCY_TWO_FIELDS = 'currency-text-two-fields',
	DATE_ONE_FIELD = 'calendar-picker',
	DATE_TWO_FIELDS = 'calendar-picker-two-fields',
	DATE_YEAR_ONE_FIELD = 'year-picker',
	DATE_YEAR_TWO_FIELDS = 'year-picker-two-fields',
	BOOLEAN = 'boolean',
	DAYS_SINCE = 'days-since',
	YEARS_SINCE = 'years-since',
	PERCENTAGE_TEXT = 'percentage-text',
	PERCENTAGE_TWO_FIELDS = 'percentage-text-two-fields',
	NUMERIC_CONDITION_SCORE_DEPRICATED = 'numeric-condition-score',
	NUMERICAL_UNRESTRICTED_ONE_FIELD = 'numeric-unrestricted-text',
	NUMERICAL_UNRESTRICTED_TWO_FIELDS = 'numeric-unrestricted-text-two-fields',
}

export enum BOOLEAN_VALUES {
	YES = 'YES',
	NO = 'NO',
}

export enum RECORD_TYPE_ENDPOINT {
	ASSET_ATTRIBUTES = 'asset',
	INSPECTION = 'inspection',
	RISK = 'riskComponents',
	CONDITION = 'condition',
	USER_DEFINED_FIELDS = 'userdefined',
	DEFECT_FILTER = 'defect',
	TASK = 'task',
}
