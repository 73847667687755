import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import settings from './settings.json';

i18n.use(
	resourcesToBackend((language, _namespace, callback) => {
		import(`../../data/translations/${language}.json`)
			.then(resources => callback(null, resources))
			.catch(err => callback(err, null));
	}),
)
	.use(initReactI18next)
	.init({
		fallbackLng: settings.defaultLanguage,
		contextSeparator: settings.contextSeparator,
		keySeparator: settings.keySeparator,
		nsSeparator: settings.nsSeparator,
		ns: settings.ns,
		interpolation: {
			escapeValue: false,
		},
		saveMissing: true,
	} as InitOptions);

export default i18n;
