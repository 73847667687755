import { StylovyzeDialog, useGlobalization } from '@innovyze/stylovyze';
import { DialogProps, FormControl, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	selectRehabTreeIsRenaming,
	selectRehabTreesList,
	selectRehabTreesNameError,
} from '@Selectors/Rehab.selectors';
import { renameRehabTree, setRehabTreeNameError } from '@Actions/index';

interface Props {
	open: boolean;
	treeId: string;
	name: string;
	onClose: (saved: boolean, newName: string) => void;
}

export const RenameRehab = ({ open, treeId, name, onClose }: Props) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	const [newName, setNewName] = useState('');
	const configNameError = selectRehabTreesNameError();
	const isRenaming = selectRehabTreeIsRenaming();
	const existingTrees = selectRehabTreesList();

	const handleCancelNewModel = () => {
		onClose(false, '');
	};

	useEffect(() => {
		dispatch(setRehabTreeNameError(''));
		setNewName(name);
	}, [name, open]);

	useEffect(() => {
		if (!isRenaming && configNameError.length == 0) {
			onClose(true, newName);
		}
	}, [isRenaming]);

	const handleRename = () => {
		if (configNameError.length == 0) {
			dispatch(renameRehabTree({ treeId: treeId, name: newName }));
		}
	};

	const handlelNameChangeValue = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		let error = '';
		if (event.target && event.target.value !== '') {
			setNewName(event.target.value);
			const duplicateName = existingTrees.filter(
				tree =>
					tree.name.toUpperCase().trim() ===
						event.target.value.toUpperCase().trim() &&
					tree._id !== treeId,
			);

			if (duplicateName.length > 0) {
				error = t('Enter a unique name');
			} else {
				const rexp = new RegExp('^[a-zA-Z0-9 ]+$');
				const test = rexp.test(event.target.value);
				if (!test) {
					error = t('Only alphanumeric characters are allowed');
				} else {
					const rexp2 = new RegExp('^.{1,50}$');
					const test2 = rexp2.test(event.target.value);
					if (!test2) {
						error = t(
							'Name cannot contain more than 50 characters',
						);
					}
				}
			}
		} else {
			setNewName('');
			error = t('Name is required');
		}
		dispatch(setRehabTreeNameError(error));
	};

	// This stops the dialog closing when clicked on the background
	const onCloseDialog = () => void {};

	const dialogProps: DialogProps = {
		open: open,
		fullWidth: true,
		maxWidth: 'sm',
		onClose: onCloseDialog,
	};

	return (
		<StylovyzeDialog
			dialogProps={dialogProps}
			data-cy="new-dialog"
			title={t('Rename Decision Tree')}
			open={open}
			cancelText={t('Cancel')}
			cancelButtonProps={{
				disabled: isRenaming,
				dataCy: 'rename-cancel',
			}}
			onCancel={handleCancelNewModel}
			confirmText={t('OK')}
			confirmButtonProps={{
				disabled:
					isRenaming ||
					configNameError.length !== 0 ||
					name.trim().toLowerCase() === newName.trim().toLowerCase(),
				dataCy: 'rename-confirm',
			}}
			onConfirm={handleRename}>
			<Grid container>
				<Grid item xs={12}>
					<FormControl variant="outlined" margin="normal" fullWidth>
						<TextField
							fullWidth
							defaultValue={name}
							error={configNameError.length !== 0}
							required
							name="name"
							label={t('Edit Name')}
							variant="outlined"
							onChange={handlelNameChangeValue}
							helperText={configNameError}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</StylovyzeDialog>
	);
};
