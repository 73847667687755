import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
	createStyles({
		formRoot: {
			height: '100%',
			width: '100%',
			'& .MuiTextField-root': {
				marginRight: '1rem',
			},
		},
		defectRoot: {
			'& .MuiChip-root': {
				background: '#F1F4F4',
			},
			'& .MuiChip-label': {
				fontSize: '12px',
			},
		},
		drawerLoader: {
			position: 'absolute',
			backgroundColor: 'rgba(0,0,0,0.1)',
			marginBottom: '0px !important',
		},
	}),
);
