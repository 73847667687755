import './App.css';

import { miniPageRoutes } from '@Pages';

import { ApplicationWrapper } from '@innovyze/stylovyze';
import React from 'react';
import { globalization } from './i18n/globalization';

function App() {
	const pages = miniPageRoutes;

	return (
		<ApplicationWrapper
			pages={pages}
			globalization={globalization}
			disableMapProvider={true}
		/>
	);
}

export default App;
