import { Grid } from '@mui/material';
import { rem } from '@Utils/styles';
import styled from 'styled-components';

export const PlaceholderGrid = styled(Grid)`
	display: ${props => (props.hidden ? 'none' : 'flex')};
	border-radius: 0px 0px 4px 4px;
	height: ${rem(371)};
	div:not(:last-child) {
		margin-bottom: ${rem(16)};
	}
`;

export const Actions = styled.div`
	& > button {
		margin-left: 0.5rem;
	}
	text-align: right;
`;
