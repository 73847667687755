import React from 'react';
import { addRouteNamespace } from '@Utils/actions';
import { RehabManagement, NewTree } from '@Pages';
import { RehabIcon } from './RehabIcon';

export const mainPageRoutes = [
	{
		title: 'Details',
		to: addRouteNamespace('details/:treeId'),
		render: <NewTree />,
	},
	{
		title: 'New Rehab Decision Tree',
		to: addRouteNamespace('new'),
		render: <NewTree />,
	},
];

export const miniPageRoutes = [
	{
		title: 'Rehab',
		to: '/rehab',
		icon: <RehabIcon />,
		render: <RehabManagement />,
	},
	...mainPageRoutes,
];

export default [mainPageRoutes, miniPageRoutes];
