import { RehabStoreState, ChoiceListStoreState } from './rehab.types';
import { moduleNamespace } from '@Store/config.store';

export interface StoreState {
	RehabStore: RehabStoreState;
	ChoiceListStore: ChoiceListStoreState;
}

export interface NamespacedStoreState {
	[moduleNamespace]: StoreState;
}

export type PartialStoreState = {};

export interface NamespacedPartialStoreState {
	[moduleNamespace]: PartialStoreState;
}
