/* eslint-disable @typescript-eslint/ban-ts-comment */
import { locales } from '@innovyze/stylovyze';
import rehabI18nSettings from './settings.json';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import stylovyzeI18nSettings from '@innovyze/stylovyze/lib/Translations.json';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as amCommonI18nSettings from '@innovyze/lib_am_common/Translations';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ns as mapI18nSettings } from '@innovyze/inno-map/Translations';

export const globalization = {
	bundles: [
		...locales.map(locale => ({
			language: locale,
			namespace: rehabI18nSettings.ns,
			file: import(`../../data/translations/${locale}.json`),
		})),
		...locales.map(locale => ({
			language: locale,
			namespace: stylovyzeI18nSettings.ns,
			file: import(`@innovyze/stylovyze/lib/translations/${locale}.json`),
		})),
		...locales.map(locale => ({
			language: locale,
			namespace: amCommonI18nSettings.ns,
			file: import(`@innovyze/lib_am_common/translations/${locale}.json`),
		})),
		...locales.map((locale: string) => ({
			language: locale,
			namespace: mapI18nSettings,
			file: import(`@innovyze/inno-map/translations/${locale}.json`),
		})),
	],
};
