import { FullPage } from '@Utils/styles';
import React from 'react';
import { DecisionTree } from '@Components';

const RehabTree = () => {
	return (
		<FullPage>
			<DecisionTree />
		</FullPage>
	);
};
export default RehabTree;
