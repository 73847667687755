import { Rules } from '@Types/rehab.types';
import React from 'react';
import SortableGroupedRuleList from './SortableGroupedRuleList';
import { arrayMoveImmutable } from 'array-move';
interface Props {
	rules: Rules[];
	rule: Rules;
	classes: any;
	ruleIdToCreateGrouped: string;
	updateRuleCondition: (rule: Rules) => void;
	updateGroupRuleCondition: (rule: Rules, groupedRule: Rules) => void;
	setRuleIdToCreateGrouped: (id: string) => void;
	onEditRule: (id: string, groupedId: string | null) => void;
	onDeleteRule: (id: string, groupedId: string | null) => void;
	handleConditionClickedForGroupedRule: (
		condition: string,
		ruleId: string,
	) => void;
	setRules: (rules: Rules[]) => void;
	setQueryPanelDisabledState: (state: boolean) => void;
	isQueryPanelDisabled: boolean;
}
const GroupedRuleContainer = ({
	rules,
	rule,
	classes,
	ruleIdToCreateGrouped,
	updateRuleCondition,
	updateGroupRuleCondition,
	setRuleIdToCreateGrouped,
	onEditRule,
	onDeleteRule,
	handleConditionClickedForGroupedRule,
	setRules,
	setQueryPanelDisabledState,
	isQueryPanelDisabled,
}: Props) => {
	const onSortGroupedRuleEnd = (sortObj: any) => {
		if (
			rule.groupedRules &&
			(sortObj.oldIndex || sortObj.oldIndex == 0) &&
			(sortObj.newIndex || sortObj.newIndex == 0)
		) {
			const newRules = arrayMoveImmutable(
				rule.groupedRules,
				sortObj.oldIndex,
				sortObj.newIndex,
			);
			const swapRuleCondition = newRules[sortObj.oldIndex].ruleCondition
				? newRules[sortObj.oldIndex].ruleCondition
				: '';
			newRules[sortObj.oldIndex] = {
				...newRules[sortObj.oldIndex],
				ruleCondition: newRules[sortObj.newIndex].ruleCondition
					? newRules[sortObj.newIndex].ruleCondition
					: '',
			};
			newRules[sortObj.newIndex] = {
				...newRules[sortObj.newIndex],
				ruleCondition: swapRuleCondition,
			};
			const updatedRule = { ...rule };
			updatedRule.groupedRules = newRules;
			const updatedRules = rules.map(rule => {
				if (rule.id == updatedRule.id) {
					return updatedRule;
				} else {
					return rule;
				}
			});
			setRules(updatedRules);
		}
	};

	function shouldCancelStart(e: any) {
		// Cancel sorting if the event target is an `input`, `textarea`, `select` or `option`
		const elementId: string = e.target.id;
		const elementClass: string = e.target.classList[0];

		if (
			(elementId && elementId.includes('sortable_groupedRule_icon')) ||
			(elementClass && elementClass.includes('ruleDragIcon'))
		) {
			return false; // Return true to cancel sorting
		}
		return true;
	}

	return (
		<SortableGroupedRuleList
			lockAxis="y"
			lockToContainerEdges
			rule={rule}
			classes={classes}
			ruleIdToCreateGrouped={ruleIdToCreateGrouped}
			updateRuleCondition={updateRuleCondition}
			updateGroupRuleCondition={updateGroupRuleCondition}
			setRuleIdToCreateGrouped={setRuleIdToCreateGrouped}
			onEditRule={onEditRule}
			onDeleteRule={onDeleteRule}
			handleConditionClickedForGroupedRule={
				handleConditionClickedForGroupedRule
			}
			onSortEnd={onSortGroupedRuleEnd}
			shouldCancelStart={shouldCancelStart}
			setQueryPanelDisabledState={setQueryPanelDisabledState}
			isQueryPanelDisabled={isQueryPanelDisabled}
		/>
	);
};
export default GroupedRuleContainer;
