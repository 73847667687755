import { createReducer } from '@reduxjs/toolkit';
import { ChoiceListStoreState } from '@Types';
import { AnyAction } from 'redux';
import {
	getAllRehabRuleChoiceListFiles,
	getAllRehabRuleChoiceListFilesRejected,
	getAllRehabRuleChoiceListFilesResolved,
	populateFieldOptions,
} from '@Actions/ChoiceList.action';

export const initState: ChoiceListStoreState = {
	isLoading: false,
	RehabRuleChoiceList: [],
	fieldOptions: [],
	allRehabRuleChoiceListFiles: {
		assetRecordType: [],
		inspectionRecordType: [],
		riskRecordType: [],
		conditionRecordType: [],
		defectFilterRecordType: [],
		udtRecordType: [],
		tasksRecordType: [],
	},
};
interface ChoiceListReducer {
	[x: string]: (
		state: ChoiceListStoreState,
		action: AnyAction,
	) => ChoiceListStoreState | undefined;
}

export const reducer: ChoiceListReducer = {
	[populateFieldOptions.toString()]: (state, action) => ({
		...state,
		fieldOptions: action.payload.fieldOptions,
	}),

	[getAllRehabRuleChoiceListFiles.toString()]: state => ({
		...state,
		isLoading: true,
	}),

	[getAllRehabRuleChoiceListFilesResolved.toString()]: (state, action) => ({
		...state,
		allRehabRuleChoiceListFiles: action.payload,
		isLoading: false,
	}),
	[getAllRehabRuleChoiceListFilesRejected.toString()]: state => ({
		...state,
		isLoading: false,
	}),
};

export const choiceListReducer = createReducer(initState, reducer);
