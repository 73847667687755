import React from 'react';

function NewTree() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="117"
			height="116"
			fill="none"
			viewBox="0 0 117 116">
			<rect
				width="15.705"
				height="20.131"
				x="37.287"
				y="42.319"
				stroke="#007CA0"
				strokeWidth="2"
				rx="1"></rect>
			<rect
				width="15"
				height="14"
				x="75.828"
				y="64.385"
				stroke="#007CA0"
				strokeWidth="2"
				rx="1"></rect>
			<rect
				width="15"
				height="14"
				x="75.828"
				y="45.385"
				stroke="#007CA0"
				strokeWidth="2"
				rx="1"></rect>
			<rect
				width="15"
				height="14"
				x="75.828"
				y="26.385"
				stroke="#007CA0"
				strokeWidth="2"
				rx="1"></rect>
			<path
				fill="#007CA0"
				d="M67.27 31.467h-1.54v2h1.54v-2zm-4.54 3v34.95h2v-34.95h-2zm3 37.95h1.54v-2h-1.54v2zm-3-3a3 3 0 003 3v-2a1 1 0 01-1-1h-2zm3-37.95a3 3 0 00-3 3h2a1 1 0 011-1v-2z"></path>
			<path
				stroke="#007CA0"
				strokeLinecap="round"
				strokeWidth="2"
				d="M57.648 52.713L71.582 52.713"></path>
			<path
				stroke="#007CA0"
				strokeLinecap="round"
				strokeWidth="2"
				d="M66.5 32.352L71.582 32.352"></path>
			<path
				stroke="#007CA0"
				strokeLinecap="round"
				strokeWidth="2"
				d="M66.5 71.303L71.582 71.303"></path>
			<ellipse
				cx="26.904"
				cy="88.436"
				fill="#fff"
				rx="26.373"
				ry="26.873"></ellipse>
			<circle
				cx="66.471"
				cy="50.308"
				r="50"
				fill="#A5BDC6"
				opacity="0.2"></circle>
			<rect
				width="42.892"
				height="43.892"
				x="6.457"
				y="66.49"
				fill="#00ABD1"
				rx="21.446"></rect>
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M35.409 89.533h-6.434v6.584H26.83v-6.584h-6.433v-2.195h6.433v-6.584h2.145v6.584h6.434v2.195z"
				clipRule="evenodd"></path>
		</svg>
	);
}

export default NewTree;
