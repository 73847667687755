import {
	SYSTEM_UNIT_TYPE,
	UNITS,
	inspectionFieldsSystemUnitType,
	assetFieldsSystemUnitType,
	wsa05InspectionFieldsSystemUnitType,
} from './unitsMappingValues';
import { RECORD_TYPE } from '../choiceListMapping';
import { InspectionStandard } from '@Types';

export const unitMapping = (fieldUnitType: string): string => {
	switch (fieldUnitType) {
		case SYSTEM_UNIT_TYPE.LENGTH_LONG:
			return UNITS.METER;
		case SYSTEM_UNIT_TYPE.LENGTH:
			return UNITS.MILLIMETER;
		case SYSTEM_UNIT_TYPE.VOLUME:
			return UNITS.CUBIC_METER;
		case SYSTEM_UNIT_TYPE.PRESSURE:
			return UNITS.METER_OF_HEAD;
		case SYSTEM_UNIT_TYPE.FLOW:
			return UNITS.CUBIC_METER_PER_SECOND;
		case SYSTEM_UNIT_TYPE.ANGLE:
			return UNITS.DEGREE;
		case SYSTEM_UNIT_TYPE.AREA:
			return UNITS.METER_SQUARE;
		case SYSTEM_UNIT_TYPE.POWER:
			return UNITS.KILO_WATT;
		case SYSTEM_UNIT_TYPE.VELOCITY:
			return UNITS.METER_PER_SECOND;
		default:
			return UNITS.MILLIMETER;
	}
};

const getUnitType = (
	systemType: string,
	assetType: string,
	field: string,
): SYSTEM_UNIT_TYPE => {
	if (!systemType || !assetType || !field) return SYSTEM_UNIT_TYPE.LENGTH;

	if (
		assetFieldsSystemUnitType[systemType] &&
		assetFieldsSystemUnitType[systemType][assetType] &&
		assetFieldsSystemUnitType[systemType][assetType][field]
	) {
		return assetFieldsSystemUnitType[systemType][assetType][field];
	}

	return SYSTEM_UNIT_TYPE.LENGTH;
};

export const getFieldSystemUnitType = (
	systemType: string,
	assetType: string,
	recordType: string,
	field: string,
	inspectionStandard: string,
): string => {
	switch (recordType) {
		case RECORD_TYPE.INSPECTION:
			switch (inspectionStandard) {
				case InspectionStandard.WSA05:
					return wsa05InspectionFieldsSystemUnitType[field]
						? wsa05InspectionFieldsSystemUnitType[field]
						: SYSTEM_UNIT_TYPE.LENGTH_LONG;
					break;
				default:
					return inspectionFieldsSystemUnitType[field]
						? inspectionFieldsSystemUnitType[field]
						: SYSTEM_UNIT_TYPE.LENGTH;
			}
		case RECORD_TYPE.ASSET_ATTRIBUTES:
			return getUnitType(systemType, assetType, field.toUpperCase());
		default:
			return SYSTEM_UNIT_TYPE.LENGTH;
	}
};
