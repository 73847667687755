import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { NamespacedStoreState } from '@Types/store.types';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const ChoiceListStoreSelector = createSelector(
	parseNamespace,
	state => state?.ChoiceListStore,
);

const RehabRuleChoiceListSelector = createSelector(
	ChoiceListStoreSelector,
	choiceListReducer => choiceListReducer?.RehabRuleChoiceList,
);

const FieldOptionsSelector = createSelector(
	ChoiceListStoreSelector,
	choiceListReducer => choiceListReducer?.fieldOptions,
);
const ChoiceListIsLoading = createSelector(
	ChoiceListStoreSelector,
	choiceListReducer => choiceListReducer?.isLoading,
);

const AllRehabRuleChoiceListFilesSelector = createSelector(
	ChoiceListStoreSelector,
	choiceListReducer => choiceListReducer?.allRehabRuleChoiceListFiles,
);
const AllRiskConfigsIdsSelector = createSelector(
	ChoiceListStoreSelector,
	choiceListReducer =>
		choiceListReducer?.allRehabRuleChoiceListFiles?.riskRecordType?.map(
			riskRecord => {
				return {
					name: riskRecord.name,
					configId: riskRecord.configId,
				};
			},
		),
);

const AllTaskConfigsIdsSelector = createSelector(
	ChoiceListStoreSelector,
	choiceListReducer =>
		choiceListReducer?.allRehabRuleChoiceListFiles?.tasksRecordType?.map(
			taskRecord => {
				return {
					name: taskRecord.name,
					configId: taskRecord.uploadId,
				};
			},
		),
);

export const selectRehabRuleChoiceList = () =>
	useSelector(RehabRuleChoiceListSelector);

export const selectFieldOptions = () => useSelector(FieldOptionsSelector);

export const selectChoiceListIsLoading = () => useSelector(ChoiceListIsLoading);

export const selectAllRehabRuleChoiceListFiles = () =>
	useSelector(AllRehabRuleChoiceListFilesSelector);

export const selectAllRiskConfigsIds = () =>
	useSelector(AllRiskConfigsIdsSelector);

export const selectAllTaskConfigsIds = () =>
	useSelector(AllTaskConfigsIdsSelector);
