import React from 'react';
import { Pagination, Row, StylovyzeHeader } from '@innovyze/lib_am_common';

import { paginationInitialState } from '@innovyze/lib_am_common/Reducers';
import { Help } from '@mui/icons-material';
import { StylovyzeTooltip } from '@innovyze/stylovyze';
import { TFunc } from '@Translations/types';

// Columns in order displayed by table
export enum Column {
	Locked = 0,
	AssetId,
	FinalRehabAction,
	TotalCost,
	CostCalculation,
	DraftRehabAction,
	MenuCell,
}

export const mapRowToCells = (row: Row, unit: string): string[] => {
	return [
		row?.LOCKED || '',
		row?.ASSET_ID || '',
		row?.FINAL_RRHAB_ACTION || '',
		row?.TOTAL_COST,
		unit == 'Metric'
			? row?.COST_CALCULATION_METER
			: row?.COST_CALCULATION_FT,
		row?.DRAFT_RRHAB_ACTION || '',
		'',
	];
};
export const lockToolTip = (t: TFunc) => {
	return (
		<StylovyzeTooltip
			heading={t('What does the lock mean?')}
			content={t(
				'Locking the rehab action will prevent manual override of assigned action/cost. This will also prevent changes to the assigned action when the decision tree is run.',
			)}
			padded>
			<Help
				fontSize="small"
				style={{ color: '#768D95', marginLeft: '3px' }}
			/>
		</StylovyzeTooltip>
	);
};

export const headerReactNodes = (unit: string, t: TFunc): StylovyzeHeader[] => {
	return [
		{
			key: 'Locked',
			cell: lockToolTip(t),
			maxWidth: '50px',
			align: 'left',
			sortable: false,
			padding: '0 10px',
		},
		{
			key: 'ASSET_ID',
			cell: t('Asset ID'),
			maxWidth: '200px',
			align: 'left',
			sortable: false,
			padding: '0 10px',
		},
		{
			key: 'FINAL_RRHAB_ACTION',
			cell: t('Rehab Action'),
			width: 'auto',
			align: 'left',
			sortable: false,
			padding: '0 10px',
		},
		{
			key: 'TOTAL_COST',
			cell: t('Total Cost'),
			width: 'auto',
			align: 'left',
			sortable: false,
			padding: '0 10px',
		},
		{
			key:
				unit == 'Metric'
					? 'COST_CALCULATION_METER'
					: 'COST_CALCULATION_FT',
			cell: t('Cost Calculation'),
			width: 'auto',
			align: 'left',
			sortable: false,
			padding: '0 10px',
		},
		{
			key: 'DRAFT_RRHAB_ACTION',
			cell: t('Draft Rehab Action'),
			width: 'auto',
			align: 'left',
			sortable: false,
			padding: '0 10px',
		},
		{
			key: 'menu',
			cell: '',
			sortable: false,
			width: '68px',
			align: 'center',
			padding: '0 10px',
		},
	];
};

export const paginationDefault = (): Pagination => ({
	...paginationInitialState,
	sortKey: 'LAST_RUN',
	sortDescending: true,
});
