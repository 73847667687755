import {
	selectRehabActions,
	selectRehabResultIsLoading,
	selectRehabResultIsWaiting,
	selectRehabResultQuery,
	selectRehabResultRows,
	selectRehabResultSearch,
	selectRehabResultTablePage,
	selectorRehabTreesPagination,
} from '@Selectors/Rehab.selectors';
import {
	getTenant,
	useSelectAssetLocation,
	useSelectAssetLocationIsWaiting,
} from '@innovyze/lib_am_common/Selectors';
import { fullAccess } from '@innovyze/shared-utils';
import {
	GridColumnVisibilityModel,
	GridFilterModel,
	GridLogicOperator,
	getSubscriptions,
	useSelectUserContext,
	useUnits,
} from '@innovyze/stylovyze';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const initialColumnVisibilityModel: GridColumnVisibilityModel = {
	__reorder__: false,
};

const initialFilterModel: GridFilterModel = {
	items: [
		{
			field: 'Locked',
			operator: 'is',
			id: 'Locked',
		},
	],
	logicOperator: GridLogicOperator.And,
};

export function useRehabResultSelectors() {
	const { system } = useUnits();

	const tablePage = selectRehabResultTablePage(system);
	const subscriptions = getSubscriptions();
	const hasFullAccess = fullAccess(subscriptions);
	const isLoading = selectRehabResultIsLoading();
	const queryString = selectRehabResultQuery();
	const isWaitingRehab = selectRehabResultIsWaiting();
	const isWaitingAssetLocation = useSelectAssetLocationIsWaiting();
	const rehabRows = selectRehabResultRows();
	const rehabActions = selectRehabActions();
	const assetLocation = useSelectAssetLocation();
	const tenantId = useSelector(getTenant);
	const { id: userId } = useSelectUserContext();
	const rehabTreesPagination = useSelector(selectorRehabTreesPagination);
	const search = selectRehabResultSearch();

	const [filterModel, setFilterModel] =
		useState<GridFilterModel>(initialFilterModel);

	const [columnVisibilityModel, setColumnVisibilityModel] =
		useState<GridColumnVisibilityModel>({
			__reorder__: false,
		});

	const resetColumnVisibilityModel = () => {
		setColumnVisibilityModel(initialColumnVisibilityModel);
	};

	const isWaiting = isWaitingRehab || isWaitingAssetLocation;

	return {
		tablePage,
		hasFullAccess,
		isLoading,
		queryString,
		isWaiting,
		rehabRows,
		rehabActions,
		assetLocation,
		tenantId,
		userId,
		rehabTreesPagination,
		search,
		columnVisibilityModel,
		setColumnVisibilityModel,
		filterModel,
		setFilterModel,
		resetColumnVisibilityModel,
	};
}
