import { FieldProps } from 'formik';
import React, { useState, useEffect } from 'react';
import Select, { OptionsOrGroups, GroupBase } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';

export interface MetadataObj {
	[key: string]: any;
}

const Div = styled.div`
	display: flex;
	flex-direction: column;

	&:hover,
	&:focus,
	&:focus-within {
		.select-label {
			color: #a5daff;
			top: -10px;
			transition: 0.2s ease all;
			font-size: 14px;
			//color: rgba(0, 0, 0, 0.26);
			background: white;
		}
	}

	label {
		font-weight: 500;
		margin-bottom: 1rem;
		color: rgba(0, 0, 0, 0.26);
		background: white;
		font-size: 18px;
		font-weight: normal;
		position: absolute;
		pointer-events: none;
		padding: 0 5px;
		left: 10px;
		top: 15px;
		transition: 0.2s ease all;
		//color:red;
	}

	.inputMultiSelect {
		&.invalid {
			.multiSelect__control {
				//background-color: #fdeaea;
				border: solid 1px red;
			}
		}
	}

	.multiSelect__control {
		position: relative;

		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		min-height: 3.5rem;
		box-shadow: none;
		background-color: transparent;
		border: none;
		width: 100%;
		outline: none;
		background-image: url('/images/dropdown-arrow.svg');
		background-repeat: no-repeat;
		background-position: right 2rem center;
		border-radius: 4px;
		// padding-right: 5rem;
		//border-radius: 2rem;
		border: solid 1px #dddddd;

		&--is-focused,
		&--is-focused:hover {
		}

		&:hover,
		&:focus,
		&:focus-within {
			border-color: #00abd1;
			.select-label {
				color: black;
			}
		}
	}

	.multiSelect__clear-indicator {
		cursor: pointer;
	}

	.multiSelect__dropdown-indicator {
		display: none;
	}

	.multiSelect__indicator-separator {
		display: none;
	}

	.multiSelect__menu {
		z-index: 999;
		border-radius: 0;
		margin-top: 0.5rem;
		border: none;
	}

	.multiSelect__multi-value {
		border-radius: 1.5rem;
		overflow: hidden;
		padding-left: 2px;
	}

	.multiSelect__multi-value__remove {
		cursor: pointer;
		transition: all 0.2s;

		&:hover {
			background-color: #cc0000;
			color: white;
		}
	}

	&.valid {
		// background-color: #cc0000;
		&:not(focus-within) {
			.select-label {
				color: rgba(0, 0, 0, 0.26);
				top: -8px;
				transition: 0.2s ease all;
				font-size: 14px;
			}
		}

		&:hover,
		&:focus,
		&:focus-within {
			.select-label {
				color: #a5daff;
			}
		}
	}

	span.error {
		color: #cc0000;
		margin-left: 0.875rem;
		margin-right: 0.875rem;
		margin-top: 0.1875rem;
		font-size: 0.75rem;
	}
`;

interface Option {
	label: string;
	value: string;
	options: string;
}

interface Iprops extends FieldProps {
	options: OptionsOrGroups<Option, GroupBase<Option>>;
	instanceId: string;
	placeholder?: string;
	label: string;
	async?: boolean;
	asyncAction?: (inputValue: any) => any;
	defaultValue?: [];
	isRequired?: boolean;
	onChangeCustom?: (arg0: any) => void;
	cacheable?: boolean;
}

export const InputMultiSelect = ({
	label,
	instanceId,
	placeholder,
	field,
	form: { touched, errors, setFieldValue, setFieldTouched },
	options,
	async = false,
	isRequired,
	onChangeCustom,
	cacheable = true,
}: Iprops) => {
	const [multiSelectValues, setMultiSelectValues] = useState<MetadataObj[]>(
		[],
	);

	useEffect(() => {
		setMultiSelectValues(field.value);
	}, [field.value]);

	const onChange = (option: any) => {
		if (option === null) {
			option = [];
		}
		setMultiSelectValues([...option]);
		setFieldValue(field.name, [...option]);
		if (onChangeCustom) onChangeCustom([...option]);
	};

	return (
		<>
			<Div
				className={
					field?.value?.length > 0
						? 'dirty valid input-group'
						: 'input-group'
				}>
				{!async ? (
					<Select
						className={
							touched[field.name] && errors[field.name]
								? 'invalid inputMultiSelect'
								: 'inputMultiSelect'
						}
						id="multiSelectInput"
						classNamePrefix="multiSelect"
						instanceId={instanceId}
						name={field.name}
						value={multiSelectValues}
						onChange={onChange}
						onBlur={() => {
							setFieldTouched(field.name);
						}}
						placeholder={placeholder}
						//cacheOptions
						isClearable
						options={options}
						isMulti
						noOptionsMessage={() => 'No Option'}
					/>
				) : (
					<AsyncSelect
						className={
							touched[field.name] && errors[field.name]
								? 'invalid inputMultiSelect'
								: 'inputMultiSelect'
						}
						id="multiSelectInput"
						classNamePrefix="multiSelect"
						instanceId={instanceId}
						name={field.name}
						onChange={onChange}
						value={multiSelectValues}
						// defaultOptions
						onBlur={() => {
							setFieldTouched(field.name);
						}}
						placeholder={placeholder}
						cacheOptions={cacheable}
						isClearable
						/*loadOptions={inputValue =>
							asyncAction && asyncAction(inputValue)
						}*/
						isMulti
						noOptionsMessage={() => 'No Option'}
					/>
				)}

				{label && (
					<label htmlFor="multiSelectInput" className="select-label">
						{label}{' '}
						{isRequired && (
							<span className="isRequired">
								this field is required
							</span>
						)}
					</label>
				)}

				{touched[field.name] && errors[field.name] && (
					<span className="error">{errors[field.name]}</span>
				)}
			</Div>
		</>
	);
};

export default InputMultiSelect;
