import { Button } from '@mui/material';
import { em } from '@Components/Utils';
import styled from 'styled-components';

export const CancelButton = styled(Button).attrs({
	variant: 'contained',
})`
	box-shadow: 0 0 0 0;
`;

export const ActionButton = styled(Button).attrs({
	variant: 'contained',
})`
	margin-left: ${em(10)};
	box-shadow: 0 0 0 0;
`;
