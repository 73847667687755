import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { MoreVert, Edit, Delete } from '@mui/icons-material';
import { useGlobalization } from '@Translations';

interface Props {
	id: string;
	groupedId: string | null;
	// handleEditClick: (id: string) => void;
	// handleDeleteClick: (id: string) => void;
	onEditRule: (id: string, groupedId: string | null) => void;
	onDeleteRule: (id: string, groupedId: string | null) => void;
	classes: any;
	setQueryPanelDisabledState: (state: boolean) => void;
	isQueryPanelDisabled: boolean;
}
export default function RowMenu({
	id,
	groupedId,
	onEditRule,
	onDeleteRule,
	classes,
	setQueryPanelDisabledState,
	isQueryPanelDisabled,
}: Props) {
	const { t } = useGlobalization();
	const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};
	const handleMenuClose = () => {
		setMenuAnchorEl(null);
	};
	const handleEditClick = (id: string, groupedId: string | null) => {
		setQueryPanelDisabledState(true);
		onEditRule(id, groupedId);
		setMenuAnchorEl(null);
	};
	const handleDeleteClick = (id: string, groupedId: string | null) => {
		onDeleteRule(id, groupedId);
		setMenuAnchorEl(null);
	};
	return (
		<div>
			<IconButton
				data-cy="threeDotMenu"
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				size="small"
				disabled={isQueryPanelDisabled}
				onClick={handleMenuClick}>
				<MoreVert />
			</IconButton>
			<Menu
				id={`action-${id}`}
				anchorEl={menuAnchorEl}
				open={Boolean(menuAnchorEl)}
				onClose={handleMenuClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				<MenuItem
					className={classes.ruleMenuItem}
					onClick={() => handleEditClick(id, groupedId)}
					data-cy="editOption">
					<Edit color="action" fontSize="small" />
					<span>{t('Edit')}</span>
				</MenuItem>
				<MenuItem
					className={classes.ruleMenuItem}
					onClick={() => handleDeleteClick(id, groupedId)}
					data-cy="deleteOption">
					<Delete color="action" fontSize="small" />
					<span>{t('Delete')}</span>
				</MenuItem>
			</Menu>
		</div>
	);
}
