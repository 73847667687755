/*eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { FieldProps } from 'formik';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.min.css";
import { DatePickerStyles } from './styles/datePicker';
import { DatePickerOverridesStyles } from './styles/datePickerOverrides';
import { subDays } from 'date-fns';


const Div = styled.div`
    display: flex;
    flex-direction: column;

    &:hover,
    &:focus,
    &:focus-within {
        .date-label {
            color: #a5daff;
            top: -10px;
            transition: 0.2s ease all;
            font-size: 14px;
            //color: rgba(0, 0, 0, 0.26);
            background: white;
        }
    }

    label {
        font-weight: 500;
        margin-bottom: 1rem;
        color: rgba(0, 0, 0, 0.26);
        background: white;
        font-size: 18px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        padding: 0 5px;
        left: 10px;
        top: 15px;
        transition: 0.2s ease all;
        //color:red;
    }

    .react-datepicker__input-container {
        position: relative;

       /* &:after {
            content: '\f073';
            display: inline-block;
            font-family: 'Font-Awesome';
            font-weight: 300 !important;
            font-style: normal !important;
            font-size: 2rem;
            display: block;
            position: absolute;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
        }*/
    }

    input {
        min-height: 3.5rem;
        border: solid 1px #dddddd;
        border-radius: .25rem;
        background-color: transparent;
        width: 100%;
        outline: none;
        background-image: url("/images/calendar-icon.svg");
        background-size: 2rem;
        background-repeat: no-repeat;
        background-position: right 1rem center; 
        height: 3rem;
        padding-left:1rem;

        &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
            background-color: #F3F3F3;

            &:hover {
                border-color: #DDDDDD;
            }
        }

        &:hover,
        &:focus,
        &:focus-within {
            border-color: #00abd1;
            .select-label {
                color: black;
            }
        }
        &.invalid {
           // background-color: #fdeaea;
           // border-color:#fdeaea;
           border: solid 1px red;

        }

        .rtl & {
            direction: rtl;
            text-align: left;
        }
     

    }
    &.valid {
        // background-color: #cc0000;
        &:not(focus-within) {
            .date-label {
                color: rgba(0, 0, 0, 0.26);
                top: -8px;
                transition: 0.2s ease all;
                font-size: 14px;
            }
        }

        &:hover,
        &:focus,
        &:focus-within {
            .date-label {
                color: #a5daff;
            }
        }
    }
    &.invalid{
        &:not(focus-within) {
            .date-label {
                background:transparent;
            }
        }
    }
    span.error {
        color: #cc0000;
        margin-left: 0.875rem;
        margin-right: 0.875rem;
        margin-top: 0.1875rem;
        font-size: 0.75rem;
    }
`;

const getFormattedDate = (dateFormat: string): string => {
    dateFormat = dateFormat.replace('DD', 'dd');
    dateFormat = dateFormat.replace('YYYY', 'yyyy');
    return dateFormat;
};

interface Iprops extends FieldProps {
    label: string;
    enableTime: boolean;
    minDateValue?: Date;
    maxDateValue?: Date;
    disabled?: boolean;
    isRequired?: boolean;
    cancelMinDate?: boolean;
    yearOnly?: boolean;
    dateFormat?: string;
}

const InputDatePicker: React.FC<Iprops> = (props) => {
    const isInitialMount = useRef(true);

    const {
        label,
        enableTime = false,
        isRequired = false,
        minDateValue,
        maxDateValue,
        cancelMinDate = false,
        field: { name, value, onChange, ...restProps },
        form: { touched, errors, setFieldValue, setFieldTouched },
        disabled = false,
        yearOnly = false,
    } = props;

    useEffect(() => {
        if (!isInitialMount.current) {
            setFieldTouched(name, true);

            if (minDateValue && !value && isRequired) {
                setFieldValue(name, minDateValue);
            }
        }
        isInitialMount.current = false;
    }, [minDateValue]);

    if (minDateValue && !value && isRequired) {
        setFieldValue(name, minDateValue);
    }

    let fixedValue = value;


    return (
        <>
            <Div className={fixedValue ? ' valid input-group' : 'input-group dirty'}>
                {enableTime ? (
                    <DatePicker
                        // onCalendarClose={() => setFieldTouched(name, true)}
                        onCalendarOpen={() => setFieldTouched(name, true)}
                        selected={fixedValue ? fixedValue : null}
                        dateFormat="d MMMM yyyy - h:mm aa"
                        showTimeSelect
                        minDate={
                            cancelMinDate
                                ? null
                                : minDateValue
                                ? minDateValue
                                : new Date()
                        }
                        maxDate={maxDateValue}
                        timeFormat="HH:mm"
                        // className="form-control"
                        className={
                            touched[name] && errors[name]
                                ? 'form-control invalid'
                                : 'form-control'
                        }
                        name={name}
                        onChange={(date) => setFieldValue(name, date)}
                        {...restProps}
                        disabled={disabled}
                    />
                ) : ( yearOnly ? (
                    <DatePicker
                        onCalendarOpen={() => setFieldTouched(name, true)}
                        selected={fixedValue ? fixedValue : null}
                        minDate={
                            cancelMinDate
                                ? null
                                : minDateValue
                                ? minDateValue
                                : new Date()
                        }
                        showYearDropdown
                        maxDate={maxDateValue}
                        dateFormat="yyyy"
                        className={
                            touched[name] && errors[name]
                                ? 'form-control invalid'
                                : 'form-control'
                        }
                        name={name}
                        onChange={(date) => setFieldValue(name, date)}
                        {...restProps}
                        disabled={disabled}
                    />
                ) : (
                    <DatePicker
                        onCalendarOpen={() => setFieldTouched(name, true)}
                        selected={fixedValue ? fixedValue : null}
                        minDate={
                            cancelMinDate
                                ? null
                                : minDateValue
                                ? minDateValue
                                : new Date()
                        }
                        maxDate={maxDateValue}
                        dateFormat={ props.dateFormat ? getFormattedDate(props.dateFormat) : 'dd/MM/yyyy' }
                        className={
                            touched[name] && errors[name]
                                ? 'form-control invalid'
                                : 'form-control'
                        }
                        name={name}
                        onChange={(date) => setFieldValue(name, date)}
                        {...restProps}
                        disabled={disabled}
                    />
                ))}

                {touched[name] && errors[name] && (
                    <span className="error">{errors[name]}</span>
                )}
                 <label  className="date-label">
                    {label}{' '}
                    {isRequired && (
                        <span className="isRequired">
                         
                        </span>
                    )}
                </label>
                <DatePickerStyles />
            <DatePickerOverridesStyles />
            </Div>
        </>
    );
};

export default InputDatePicker;
