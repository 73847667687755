import React, {
	useState,
	useEffect,
	MouseEvent as ReactMouseEvent,
} from 'react';
import ReactFlow, {
	ReactFlowProvider,
	Controls,
	Elements,
	Edge,
	Node,
	MiniMap,
} from 'react-flow-renderer';
import { useParams } from 'react-router-dom';
import { selectCurrentTree } from '@Selectors/Rehab.selectors';

import './dnd.css';
import { useDispatch } from 'react-redux';
import { getRehabTree } from '@Actions';
import SourceNode from '@Components/ReactFlowNodes/QueryNode.component';
import InnoInputNode from '@Components/ReactFlowNodes/InnoInputNode';
const nodeTypes = {
	sourceNode: SourceNode,
	innoInput: InnoInputNode,
	//   dataNode: DataNode,
	//   functionNode: FunctionNode,
	//   valueNode: ValueNode
};
export const TreeView = () => {
	const dispatch = useDispatch();
	const params: { treeId: string } = useParams();

	const currentTree = selectCurrentTree();

	const [elements, setElements] = useState<Elements>([]);

	const style = { width: '100%', height: '600px' };
	const onElementClick = (event: ReactMouseEvent, element: Node | Edge) => {
		console.log('click', element);
	};

	useEffect(() => {
		dispatch(
			getRehabTree({
				Id: params.treeId,
			}),
		);
	}, []);

	useEffect(() => {
		if (currentTree != null && currentTree?.nodes.length != 0) {
			let nodes: any[] = [];
			nodes = currentTree.nodes;
			console.log('We now have a tree............');
			setElements(nodes);
		}
	}, [currentTree]);

	return (
		<div className="dndflow">
			<ReactFlowProvider>
				<div className="reactflow-wrapper">
					<ReactFlow
						elements={elements}
						// onElementsRemove={handleElementsRemove}
						// onConnect={onConnect}
						onElementClick={onElementClick}
						nodeTypes={nodeTypes}
						style={style}>
						<Controls />
						<MiniMap
							nodeColor={node => {
								switch (node.type) {
									case 'input':
										return 'gold';
									case 'default':
										return '#00ff00';
									case 'output':
										return 'blue';
									default:
										return '#eee';
								}
							}}
							nodeStrokeWidth={1}
						/>
					</ReactFlow>
				</div>
			</ReactFlowProvider>
		</div>
	);
};

export default TreeView;
