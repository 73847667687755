import { Elements, isNode } from 'react-flow-renderer';
export const getLabelForDuplicate = (
	originalLabel: string,
	elements: Elements<any>,
): string => {
	let newLabel: string;
	const trainingNumbersRegex = /copy-\d+$/;
	const endsWithNumbers = originalLabel.match(trainingNumbersRegex);
	if (endsWithNumbers) {
		const numbersRegex = /\d+$/;
		const [suffix] = originalLabel.match(numbersRegex) ?? [];
		const trailingNumbers = Number.parseInt(suffix);
		const newSuffix = trailingNumbers + 1;
		newLabel = originalLabel.replace(numbersRegex, `${newSuffix}`);
	} else if (originalLabel.endsWith('copy')) {
		newLabel = originalLabel + '-1';
	} else {
		newLabel = originalLabel + '-copy';
	}

	const matchingEle = elements
		.filter(e => isNode(e))
		.find(e => e.data.label === newLabel);
	if (matchingEle) {
		return getLabelForDuplicate(newLabel, elements);
	} else {
		return newLabel;
	}
};
