import { createSelector } from '@reduxjs/toolkit';
import { UserContextStoreState } from '@innovyze/stylovyze';
import { useSelector } from 'react-redux';

const parseNamespace = (state: UserContextStoreState) => state['userContext'];

const UserContextSelector = createSelector(parseNamespace, state => state);

export const selectUserContext = () => useSelector(UserContextSelector);

export default selectUserContext;
