import {
	Column as RiskDetailsColumn,
	headerReactNodes as rehabResultHeaderReactNodes,
} from './rehabResultPagination';
import { FilterStoreState } from '@innovyze/lib_am_common';
import {
	filterByIdSelect,
	filterByMultipleIdSelect,
	noFilters,
} from '@innovyze/lib_am_common/Reducers';
import { t } from 'i18next';

export const rehabResultInitialState = (): FilterStoreState => {
	const headers = rehabResultHeaderReactNodes('Metric', t);
	const draftRehabAction = headers[RiskDetailsColumn.DraftRehabAction];
	const finalRehabAction = headers[RiskDetailsColumn.FinalRehabAction];

	return {
		query: '',
		filters: {},
		selects: [
			// {
			// 	column: { id: grade.key, caption: grade.cell },
			// 	options: gradeFilterOptions(),
			// 	dataCy: grade.key,
			// 	filterBy: filterByMultipleIdSelect,
			// 	value: [],
			// 	multiple: true,
			// },
			//Supposely this filter is for final rehab action, but for now draft rehab is easier, we keep the key to be final action
			{
				column: {
					id: finalRehabAction.key,
					caption: draftRehabAction.cell,
				},
				options: [],
				dataCy: finalRehabAction.key,
				filterBy: filterByMultipleIdSelect,
				value: [],
				multiple: true,
			},
			{
				column: {
					id: 'ActionsNotEqual',
					caption: 'Final Action ≠ Draft Action',
				},
				options: [
					noFilters(),
					{ id: 'true', caption: 'true' },
					{ id: 'false', caption: 'false' },
				],
				dataCy: 'ActionsNotEqual',
				filterBy: filterByIdSelect,
				value: 'false',
			},
			{
				column: {
					id: 'LockedOnly',
					caption: 'Only show locked assets',
				},
				options: [
					noFilters(),
					{ id: 'true', caption: 'true' },
					{ id: 'false', caption: 'false' },
				],
				dataCy: 'LockedOnly',
				filterBy: filterByIdSelect,
				value: 'false',
			},
		].reduce((d, v) => ({ ...d, [v.column.id]: v }), {}),
	};
};
