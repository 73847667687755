export const Colors = {
	white: '#fff',
	black: '#000',
	logoBrown: '#f28507',
	tabIconDefault: '#959292',
	tabBar: '#fefefe',
	errorBackground: 'red',
	errorText: '#fff',
	warningBackground: '#EAEB5E',
	warningText: '#666804',
	noticeText: '#fff',
	colorService: {
		Collector: '#036bfc',
		Curator: '#795548',
		Transformer: '#d99918',
		Mapper: '#ffe57f',
		Publisher: '#f28507',
	},
	colorCollector: '#036bfc',
	colorCurator: '#795548',
	colorTransformer: '#d99918',
	colorMapper: '#ffe57f',
	colorPublisher: '#f28507',
	colorConnection: '#c95b06',
};
