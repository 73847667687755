import { createStyles, makeStyles, withStyles } from '@mui/styles';
import { rem } from '@Utils/styles';
import styled from 'styled-components';

import { TimelineItem as MuiTimelineItem } from '@mui/lab';

export const useStyles = makeStyles(() =>
	createStyles({
		root: {
			width: '100%',
			paddingTop: '21px',
		},
		heading: {
			fontWeight: 600,
			fontSize: '18px',
			lineHeight: '24px',
			color: '#272833',
		},
		expandText: {
			fontWeight: 600,
			fontSize: '14px',
			lineHeight: '20px',
			color: '#4A6067',
		},
		ruleLine: {
			display: 'flex',
			overflowWrap: 'anywhere',
		},
		ruleType: {
			color: '#FD7B32',
		},
		ruleOperator: {
			color: '#007CA0',
			'&:before': {
				content: '"\\00a0"',
			},
			'&:after': {
				content: '"\\00a0"',
			},
		},
		ruleValue: {
			color: '#4c3dad',
			textTransform: 'uppercase',
		},
		groupedRuleWrapper: {
			color: '#999',
			padding: '17px 0 0 7px',
			fontSize: '12px',
		},
		addRuleBtn: {
			border: 'none',
			color: '#999',
			fontSize: '.75rem',
			paddingLeft: '1rem',
		},
		addGroupedRuleGrid: {
			marginTop: '.625rem',
			marginLeft: '-0.7rem',
			flexWrap: 'nowrap',
		},
		addGroupedRuleBtn: {
			border: 'none',
			color: '#999',
			fontSize: '.75rem',
		},
		ruleMenuItem: {
			fontSize: '12px',
			padding: '3px 5px',
			'& > span': {
				color: '#4A6067',
				paddingLeft: '5px',
			},
		},

		ruleConditionAnd: {
			color: '#4A6067',
			fontSize: '.625rem',
			backgroundColor: '#F1F4F4',
			borderTopRightRadius: '0',
			borderBottomRightRadius: '0',
			border: 'none',
			minWidth: '2rem',
			width: '3rem',
			height: '1.4rem',
		},
		ruleConditionOr: {
			color: 'white',
			fontSize: '.625rem',
			minWidth: '2rem',
			width: '3rem',
			height: '1.4rem',
			backgroundColor: '#007CA0',
			borderTopLeftRadius: '0',
			borderBottomLeftRadius: '0',
			border: 'none',
			'&:hover': {
				backgroundColor: '#007CA0',
			},
		},

		ruleConditionConnector: {
			position: 'absolute',
			top: '-1.6rem',
			left: '-1rem',
			minWidth: '2rem',
			width: '3rem',
			height: '1.4rem',
			color: 'white',
			fontSize: '.625rem',
			backgroundColor: '#007CA0',
			border: 'none',
			'&:hover': {
				backgroundColor: '#007CA0',
			},
		},
		addRuleGrid: {
			//marginTop: '.687rem',
		},
		ruleItem: {
			marginTop: '2.31rem',
			'&:first-of-type': {
				marginTop: '0',
			},
			zIndex: 2000,
		},
		timeLineDot: {
			backgroundColor: '#007CA0',
			position: 'relative',
			'&:after': {
				position: 'absolute',
				top: '2px',
				left: '-6px',
				content: '""',
				borderTop: '0.125rem solid #D6DDDF',
				width: '1rem',
				margin: '0 1rem',
				//transform: 'translateY(-1rem)'
			},
		},

		timeLineConnector: {
			backgroundColor: '#D6DDDF',
			marginTop: '-.5rem',
		},
		groupedRuleItemContent: {
			paddingLeft: '2.25rem',
		},
		timeLineDotGroupedRule: {
			backgroundColor: '#007CA0',
			position: 'relative',
			'&:after': {
				position: 'absolute',
				top: '2px',
				left: '-6px',
				content: '""',
				borderTop: '0.125rem solid #D6DDDF',
				width: '2rem',
				margin: '0 1rem',
				//transform: 'translateY(-1rem)'
			},
		},
		groupedRuleItem: {
			position: 'relative',
			marginTop: '2rem',
			'&:after': {
				position: 'absolute',
				top: '8px',
				left: '-33px',
				content: '""',
				borderTop: '0.125rem solid #D6DDDF',
				width: '1rem',
				margin: '0 1rem',
				//transform: 'translateY(-1rem)'
			},

			'&:before': {
				position: 'absolute',
				top: '-44px',
				left: '-33px',
				content: '""',
				borderTop: '3.4rem solid #D6DDDF',
				width: '.125rem',
				margin: '0 1rem',
				//transform: 'translateY(-1rem)'
			},
		},
		groupedRuleConditionConnector: {
			left: '.8rem',
		},
		ruleDragIcon: {
			pointerEvents: 'auto',
			'&:hover': {
				cursor: 'grab',
			},
		},
		ruleDragIconDisabled: {
			pointerEvents: 'none',
		},
		filterNumText: {
			paddingLeft: '1rem',
		},
	}),
);
export const TimelineItem = withStyles({
	missingOppositeContent: {
		'&:before': {
			display: 'none',
		},
	},
	content: {
		//padding: '6px 0px',
	},
})(MuiTimelineItem);

export const MenuWrapper = styled.div`
	position: absolute;
	top: ${rem(8)};
	right: ${rem(8)};
`;

export const SortableItem = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	&:hover {
		//cursor: -webkit-grab;
		// cursor: grab;
	}
`;
