import { useEffect, useState } from 'react';

export default function useFirstMount(fn: () => void, deps: any[] = []) {
	const [firstMount, setFirstMount] = useState<boolean>(true);

	useEffect(() => setFirstMount(true), deps);

	useEffect(() => {
		if (firstMount) {
			fn();
			setFirstMount(false);
		}
	}, [firstMount]);
}
