import config, { apiEnv } from './config.api';

import axios from 'axios';
import cookies from 'browser-cookies';
import { getService } from '@innovyze/stylovyze';
import { formatUrlTrailing } from '@Utils/urlFormatting';

const options = {
	baseURL: getService('amRehab' as any, config, apiEnv),
	timeout: 30000,
};

const rehabService = axios.create(options);

rehabService.interceptors.request.use(
	config => {
		config.headers.Authorization =
			'Bearer ' + cookies.get('auth0.user.token');
		return config;
	},
	error => {
		Promise.reject(error);
	},
);

export const rehabBaseUrl = formatUrlTrailing(
	getService('amRehab', config, apiEnv),
);

export default rehabService;
