import { CostUnit, RehabAction } from '@Types';
import * as uuid from 'uuid';
import {
	Drawer,
	closeDrawer,
	useUnits,
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
	CurrencyField,
} from '@innovyze/stylovyze';
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectRehabActions } from '@Selectors/Rehab.selectors';
import { addRehabAction, saveRehabAction } from '@Actions/Rehab.actions';
import { useGlobalization } from '@Translations';
import { SelectChangeEvent } from '@mui/material';

interface Props {
	treeId: string;
	viewerAccess: boolean;
	rehabAction: RehabAction | undefined | null;
}

export const RehabActionDrawer = ({
	treeId,
	viewerAccess,
	rehabAction,
}: Props) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const [validName, setValidName] = useState<string>(
		rehabAction ? '' : 'Enter a valid name',
	);
	const [name, setName] = useState<string>(
		rehabAction ? rehabAction.actionId : '',
	);
	const { system } = useUnits();

	const [initialUnitCost, setInitialUnitCost] = useState<number>(
		rehabAction ? rehabAction.unitCost : 0,
	);
	const [unitCost, setUnitCost] = useState<number>(
		rehabAction ? rehabAction.unitCost : 0,
	);
	const [validUnitCost, setValidUnitCost] = useState<string>('');

	const [costUnit, setCostUnit] = useState<CostUnit>(
		rehabAction ? rehabAction.unit : CostUnit.length,
	);

	const [notes, setNotes] = useState<string>(
		rehabAction ? rehabAction.notes : '',
	);

	const [defectCodes, setDefectCodes] = useState<string[] | null>(
		rehabAction && rehabAction.settings && rehabAction.settings.defects
			? rehabAction.settings.defects
			: [],
	);

	const rehabActions = selectRehabActions();

	const updateValue = (
		event: ChangeEvent<
			| HTMLInputElement
			| HTMLInputElementExtended
			| HTMLTextAreaElementExtended
		>,
		sField: 'Name' | 'UnitCost' | 'Notes',
	) => {
		const sValue = event.target.value;
		switch (sField) {
			case 'Name':
				{
					let error = '';
					if (sValue.trim().length == 0) {
						error = t('Enter a valid name');
					} else {
						const rexp = new RegExp(/^[a-zA-Z0-9 ._@-]*$/);
						const test = rexp.test(sValue);
						if (!test) {
							error = t(
								'Only letters, numbers, spaces and some special characters (._@-) are allowed',
							);
						} else if (!rehabAction) {
							const valid =
								rehabActions?.find(
									rehabAction =>
										rehabAction.actionId.toUpperCase() ===
										sValue.toUpperCase(),
								) === undefined;
							if (!valid) error = t('Enter a unique name');
						} else if (sValue != rehabAction.actionId) {
							const valid =
								rehabActions?.find(
									rehabAction =>
										rehabAction.actionId.toUpperCase() ===
										sValue.toUpperCase(),
								) === undefined;
							if (!valid) error = t('Enter a unique name');
						}
					}

					setValidName(error);
					setName(event.target.value);
				}
				break;
			case 'UnitCost':
				{
					const actualEntry = parseFloat(event.target.value);
					let error = '';
					if (actualEntry < 0) {
						error = t('Unit cost must be greater than 0');
					}
					const dValue = Number(sValue);
					setValidUnitCost(error);
					setUnitCost(error.length == 0 ? dValue : -1);
				}
				break;
			case 'Notes': {
				setNotes(sValue);
				break;
			}
			default:
				break;
		}
	};

	const handleCostUnitSelectChange = (event: SelectChangeEvent<string>) => {
		const target = event?.target as HTMLInputElement;
		setInitialUnitCost(0);
		setUnitCost(0);
		setValidUnitCost('');
		setCostUnit(target?.value as CostUnit);
	};

	const clearFields = () => {
		setName('');
		setValidName('');
		setInitialUnitCost(0);
		setUnitCost(0);
		setValidUnitCost('');
		setCostUnit(CostUnit.length);
		setNotes('');
		setDefectCodes([]);
	};

	const fieldCostUnits = [
		{
			value: CostUnit.length,
			label: system === 'Metric' ? t('/m (length)') : t('/ft (length)'),
		},
		{ value: CostUnit.each, label: t('each') },
	];

	const menuItemsCostUnit = fieldCostUnits.map(
		(item: { value: string; label: string }, index: number) => (
			<MenuItem key={index} value={item.value}>
				{item.label}
			</MenuItem>
		),
	);

	const nameInputs = (
		<Grid xs={12} item>
			<TextField
				fullWidth
				required
				name="Name"
				onChange={(
					// eslint-disable-next-line prettier/prettier
					event: ChangeEvent<HTMLInputElement>,
				) => updateValue(event, 'Name')}
				value={name}
				label={t('Name')}
				variant="outlined"
				helperText={validName}
				error={validName != ''}
			/>
		</Grid>
	);

	const unitCostInputs = (unit: CostUnit) => {
		return (
			<Grid xs={6} item>
				<CurrencyField
					min={0}
					step={'1'}
					fullWidth
					name="UnitCost"
					precision={2}
					onChange={(
						// eslint-disable-next-line prettier/prettier
					event: ChangeEvent<
							HTMLInputElement | HTMLTextAreaElementExtended
						>,
					) => updateValue(event, 'UnitCost')}
					defaultValue={initialUnitCost.toString()}
					unit={unit == CostUnit.length ? 'cost/m' : undefined}
					label={t('Unit Cost')}
					variant="outlined"
					helperText={validUnitCost}
					error={validUnitCost != ''}
				/>
			</Grid>
		);
	};

	const costUnitInputs = (
		<Grid xs={6} item>
			<FormControl variant="outlined" fullWidth disabled={viewerAccess}>
				<InputLabel>{t('Cost Unit')}</InputLabel>
				<Select
					value={costUnit}
					data-cy="costUnitField"
					name="CostUnit"
					onChange={handleCostUnitSelectChange}
					label={t('Cost Unit')}>
					{menuItemsCostUnit}
				</Select>
			</FormControl>
		</Grid>
	);

	const notesInputs = (
		<Grid xs={12} item>
			<TextField
				fullWidth
				name="Notes"
				onChange={(
					// eslint-disable-next-line prettier/prettier
					event: ChangeEvent<HTMLInputElement>,
				) => updateValue(event, 'Notes')}
				value={notes}
				label={t('Notes')}
				variant="outlined"
			/>
		</Grid>
	);

	const handleAddOrSave = () => {
		if (name != '' && unitCost >= 0) {
			if (!rehabAction) {
				const newRehabAction: RehabAction = {
					_id: uuid.v4(),
					actionId: name,
					treeId: treeId,
					unitCost: unitCost,
					costUnit: 'USD', //"USD" or "GBP", now is not used
					unit: costUnit as CostUnit,
					settings: {
						enabled: true,
						defects: defectCodes ?? [],
					},
					notes: notes,
				};
				dispatch(addRehabAction({ rehabAction: newRehabAction }));
			} else {
				if (rehabAction && rehabAction._id != '') {
					const modifiedRehabAction: RehabAction = {
						_id: rehabAction._id,
						actionId: name,
						treeId: treeId,
						unitCost: unitCost,
						costUnit: 'USD', //"USD" or "GBP", now is not used
						unit: costUnit as CostUnit,
						settings: {
							enabled: true,
							defects: defectCodes ?? [],
						},
						notes: notes,
					};
					dispatch(
						saveRehabAction({ rehabAction: modifiedRehabAction }),
					);
				}
			}
			return true;
		} else return false;
	};

	return (
		<Drawer
			action={{
				label: rehabAction ? t('Save') : t('Add'),
				onClick: function noRefCheck() {
					const valid = handleAddOrSave();
					if (valid) {
						dispatch(closeDrawer());
					}
				},
				disabled: validUnitCost != '' || validName != '',
			}}
			drawerId="addNewActionDrawer"
			onCancel={() => dispatch(closeDrawer())}
			onOpen={() => {
				if (rehabAction) {
					setName(rehabAction.actionId);
					setValidName('');
					setInitialUnitCost(rehabAction.unitCost);
					setUnitCost(rehabAction.unitCost);
					setCostUnit(rehabAction.unit);
					setNotes(rehabAction.notes);
					setDefectCodes(rehabAction.settings?.defects);
				} else {
					clearFields();
				}
			}}
			title={rehabAction ? t('Edit') : t('New Action')}>
			<form noValidate autoComplete="off">
				<Grid container spacing={2}>
					{nameInputs}
					{unitCostInputs(costUnit)}
					{costUnitInputs}
					{notesInputs}
				</Grid>
			</form>
		</Drawer>
	);
};
