import React from 'react';

export const RehabIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="18"
		height="17"
		fill="none"
		viewBox="0 0 18 17">
		<path stroke="#fff" d="M1.168 0.951H7.168V6.951H1.168z"></path>
		<path stroke="#fff" d="M1.168 9.965H7.168V15.965H1.168z"></path>
		<path stroke="#fff" d="M10.866 9.979H16.866V15.979H10.866z"></path>
		<path
			fill="#fff"
			d="M3.513 6.93H4.5009999999999994V10.094H3.513z"></path>
		<path
			fill="#fff"
			d="M7.364 13.709H8.352V16.873H7.364z"
			transform="rotate(-90 7.364 13.709)"></path>
		<path
			stroke="#fff"
			strokeWidth="0.5"
			d="M3.607 13.748l-.829-.829-.282.28 1.111 1.112 2.387-2.386-.28-.28-2.107 2.103z"></path>
		<path
			fill="#fff"
			stroke="#fff"
			strokeWidth="0.5"
			d="M12.456 11.838l.279-.28 1.107 1.108 1.108-1.107.279.279-1.107 1.107 1.107 1.108-.28.279-1.107-1.107-1.107 1.107-.28-.28 1.108-1.107-1.107-1.107z"></path>
	</svg>
);
