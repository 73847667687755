import { createStyles, makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { rem } from '@Utils/styles';
import styled from 'styled-components';

export const CanvasContainer = styled.div`
	height: ${rem(712)};
	background-color: white;
	background-size: 50px 50px;
	display: flex;
	> * {
		height: 100%;
		min-height: 100%;
		width: 100%;
	}
`;

export const PlaceholderGrid = styled(Grid)`
	display: ${props => (props.hidden ? 'none' : 'flex')};
	border-radius: 0px 0px 4px 4px;
	height: ${rem(371)};
	div:not(:last-child) {
		margin-bottom: ${rem(16)};
	}
`;

export const useStyles = makeStyles(() =>
	createStyles({
		newTreeCard: {
			padding: '1rem',
		},
		newTreeCardAction: {
			justifyContent: 'end',
		},
		newTreeCardContent: {
			maxWidth: 312,
		},
		newTreeAssetTypeSelect: {
			marginTop: 8,
		},
		newTreeCardHeader: {},
		newTreeAddButton: {
			marginRight: '1.2rem',
		},
		treeActions: {
			//flexBasis: 'auto',
			width: '50%',
		},
		validTreeButton: {
			background: '#2e7d32',
			color: 'white',
		},
		invalidTreeButton: {
			background: '#d32f2f',
			color: 'white',
		},
		treeActionsContainer: {
			display: 'flex',
		},
		treeActionsRight: {
			display: 'flex',
			alignItems: 'center',
			width: '50%',
			justifyContent: 'end',
		},
		treeActionsRightSave: {
			marginLeft: '.625rem',
		},
		treeActionsRightRun: {
			marginLeft: '1rem',
		},
		treeActionsRightText: {
			color: '#768D95',
			width: '8rem',
		},
		canvasReactFlowEmpty: {
			marginTop: '1.5rem',
			height: '72vh',
			backgroundColor: '#FFFFFF',
			boxShadow:
				' 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.2)',
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
		},
		canvasReactFlow: {
			marginTop: '1.5rem',
			height: '72vh',
			backgroundColor: '#FFFFFF',
			boxShadow:
				' 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.2)',
		},
		canvasReactFlowEmptyIcon: {
			marginTop: '9rem',
		},
		canvasReactFlowEmptyActions: {
			marginTop: '1.2rem',
		},
		canvasReactFlowEmptyActionsQuery: {
			marginRight: '.625rem',
		},
		canvasReactFlowEmptyNote: {
			color: '#768D95',
		},
		canvasReactFlowEmptyTitle: {
			color: '#4A6067',
		},
		codeListTag: {
			background: '#F1F4F4',
		},
		codeListOption: {
			'&[aria-selected="true"]': {
				background: '#007CA0',
				color: 'white',
			},
		},
		errorsButton: {
			color: '#AA0000',
			textDecoration: 'underline',
			verticalAlign: 'baseline',
		},
	}),
);
