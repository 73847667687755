import * as uuid from 'uuid';
import React, { memo } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import Node, { contentStyle as style } from './CustomNode';
import { useGlobalization } from '@Translations';

// const isValidConnection = (connection) => {
//   return R.last(R.split('__', connection.target)) === 'data';
// };
interface Props {
	data: any;
	selected: boolean;
}
const SourceNode = ({ data }: Props) => {
	const { t } = useGlobalization();
	return (
		<fieldset style={style.fs}>
			<legend style={style.legend}>{t('Query')}</legend>
			<Node
				label={data.name}
				color={'LemonChiffon'}
				content={
					<div>
						{data.label}
						<Handle
							type="source"
							position={Position.Right}
							id={uuid.v4()}
						/>
						<Handle
							type="source"
							position={Position.Left}
							id={uuid.v4()}
						/>
						<Handle
							type="source"
							position={Position.Top}
							id={uuid.v4()}
						/>
						<Handle
							type="source"
							position={Position.Bottom}
							id={uuid.v4()}
						/>
					</div>
				}
			/>
		</fieldset>
	);
};

export default memo(SourceNode);
