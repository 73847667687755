import { Typography } from '@mui/material';
import { rem } from '@Utils/styles';
import { Form } from 'formik';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const FullHeight = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: 1.5rem 1.5rem 0 0rem;
`;

export const StyledForm = styled(Form)`
	& > div {
		margin-bottom: 1.5rem;
	}
	& > button {
		margin-bottom: 1.5rem;
	}
	& > .MuiFormGroup-root {
		flex-direction: row;
	}
`;

export const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 1.5rem;
	& > button {
		margin-left: 1rem;
	}
`;

export const ActionsLeft = styled.div`
	display: flex;
	justify-content: flex-start;
	padding: 1.5rem;
	& > button {
		margin-left: 1rem;
	}
`;

export const EditorLabel = styled(Typography)`
	font-size: 12px;
	color: rgba(0, 0, 0, 0.87);
	margin: 0 0 ${rem(4)} ${rem(4)};
`;
