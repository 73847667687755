import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import {
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
} from '@mui/lab';
import { Rules, RULE_CONDITION } from '@Types/rehab.types';
import { SortableItem, TimelineItem, useStyles } from '../RulesList.styles';

import { Button, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import { showRuleValues } from '../RulesList.component';
import { DragIndicator } from '@mui/icons-material';
import { selectAllRehabRuleChoiceListFiles } from '@Selectors/ChoiceList.selectors';
import RowMenu from '../RowMenu';
import GroupedRuleContainer from './GroupedRuleContainer';
import { useGlobalization } from '@Translations';

interface Props {
	rule: Rules;
	sortIndex: number;
	classes?: any;
	ruleIdToCreateGrouped: string;
	updateRuleCondition: (rule: Rules) => void;
	updateGroupRuleCondition: (rule: Rules, groupedRule: Rules) => void;
	setRuleIdToCreateGrouped: (id: string) => void;
	setShowRuleCondition: (show: boolean) => void;
	onEditRule: (id: string, groupedId: string | null) => void;
	onDeleteRule: (id: string, groupedId: string | null) => void;
	handleConditionClickedForGroupedRule: (
		condition: string,
		ruleId: string,
	) => void;
	setRules: (rules: Rules[]) => void;
	rules: Rules[];
	setQueryPanelDisabledState: (state: boolean) => void;
	isQueryPanelDisabled: boolean;
	isFilter: boolean;
}

export const SortableRuleItem = SortableElement(
	({
		rule,
		//classes,
		ruleIdToCreateGrouped,
		updateRuleCondition,
		updateGroupRuleCondition,
		setRuleIdToCreateGrouped,
		setShowRuleCondition,
		onEditRule,
		onDeleteRule,
		handleConditionClickedForGroupedRule,
		setRules,
		rules,
		setQueryPanelDisabledState,
		isQueryPanelDisabled,
		isFilter,
	}: Props) => {
		const { t } = useGlobalization();
		const classes = useStyles();
		const recordTypeChoiceListFiles = selectAllRehabRuleChoiceListFiles();

		return (
			<TimelineItem className={classes.ruleItem}>
				{rule.ruleCondition && (
					<Button
						className={classes.ruleConditionConnector}
						variant="contained"
						onClick={() => updateRuleCondition(rule)}
						disabled={isQueryPanelDisabled}>
						{rule.ruleCondition}
					</Button>
				)}
				<TimelineSeparator>
					<TimelineDot
						className={
							rule.groupedRules && rule.groupedRules.length > 0
								? classes.timeLineDotGroupedRule
								: classes.timeLineDot
						}></TimelineDot>
					<TimelineConnector className={classes.timeLineConnector} />
				</TimelineSeparator>

				<TimelineContent
					className={
						rule.groupedRules && rule.groupedRules.length > 0
							? classes.groupedRuleItemContent
							: ''
					}>
					<SortableItem id="sortable-rule">
						{showRuleValues(
							recordTypeChoiceListFiles,
							rule,
							classes,
							<DragIndicator
								id="sortable-rule_icon"
								color="disabled"
								fontSize="small"
								className={
									isQueryPanelDisabled
										? classes.ruleDragIconDisabled
										: classes.ruleDragIcon
								}
							/>,
						)}
					</SortableItem>
					{rule.filters && rule.filters.length > 0 ? (
						<div className={classes.filterNumText}>
							{t('{{num}} {{filterText}} Added', {
								num: rule.filters.length,
								filterText:
									rule.filters.length > 1
										? 'Filters'
										: 'Filter',
							})}
						</div>
					) : null}
					<div>
						<GroupedRuleContainer
							rules={rules}
							rule={rule}
							classes={classes}
							ruleIdToCreateGrouped={ruleIdToCreateGrouped}
							updateRuleCondition={updateRuleCondition}
							updateGroupRuleCondition={updateGroupRuleCondition}
							setRuleIdToCreateGrouped={setRuleIdToCreateGrouped}
							onEditRule={onEditRule}
							onDeleteRule={onDeleteRule}
							handleConditionClickedForGroupedRule={
								handleConditionClickedForGroupedRule
							}
							setRules={setRules}
							//	shouldCancelStart={shouldCancelStart}
							setQueryPanelDisabledState={
								setQueryPanelDisabledState
							}
							isQueryPanelDisabled={isQueryPanelDisabled}
						/>
					</div>
					<Grid
						container
						className={classes.addGroupedRuleGrid}
						alignItems="center"
						spacing={1}>
						<Grid item xs={6}>
							<Button
								className={classes.addGroupedRuleBtn}
								variant="outlined"
								startIcon={<Add />}
								onClick={() => {
									setRuleIdToCreateGrouped(
										rule.id == ruleIdToCreateGrouped
											? ''
											: rule.id,
									);
									setShowRuleCondition(false);
								}}
								disabled={isQueryPanelDisabled}>
								{t(
									isFilter
										? 'Add Grouped Filter'
										: 'Add Grouped Rule',
								)}
							</Button>
						</Grid>

						{rule.id == ruleIdToCreateGrouped &&
							!isQueryPanelDisabled && (
								<Grid item xs={6}>
									<Button
										className={classes.ruleConditionAnd}
										variant="outlined"
										onClick={() =>
											handleConditionClickedForGroupedRule(
												RULE_CONDITION.AND,
												rule.id,
											)
										}>
										{RULE_CONDITION.AND}
									</Button>
									<Button
										className={classes.ruleConditionOr}
										variant="outlined"
										onClick={() =>
											handleConditionClickedForGroupedRule(
												RULE_CONDITION.OR,
												rule.id,
											)
										}>
										{t('OR')}
									</Button>
								</Grid>
							)}
					</Grid>
				</TimelineContent>
				<RowMenu
					id={rule.id}
					groupedId={null}
					onEditRule={onEditRule}
					onDeleteRule={onDeleteRule}
					classes={classes}
					setQueryPanelDisabledState={setQueryPanelDisabledState}
					isQueryPanelDisabled={isQueryPanelDisabled}
				/>
			</TimelineItem>
		);
	},
);
