import React, {
	useState,
	useEffect,
	MouseEvent as ReactMouseEvent,
	useMemo,
	useCallback,
} from 'react';
import * as uuid from 'uuid';
import ReactFlow, {
	Elements,
	ReactFlowProvider,
	Controls,
	MiniMap,
	OnLoadParams,
	Connection,
	Edge,
	ArrowHeadType,
	ConnectionMode,
	Node,
	isNode,
	removeElements,
	XYPosition,
	isEdge,
} from 'react-flow-renderer';
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Typography,
	Select,
	Paper,
	Link,
	IconButton,
} from '@mui/material';
import { Add, Create } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import {
	closeDrawer,
	Drawer,
	getSubscriptions,
	NavigationPrompt,
	openDrawer,
	StylovyzeDialog,
	Text,
	WarningDialog,
	success,
	error,
} from '@innovyze/stylovyze';
import {
	CostUnit,
	NodePositionParams,
	NODE_TYPES,
	ReactFlowSchema,
	RehabAction,
} from '@Types';
import {
	addNodeToNewTree,
	addRehabTree,
	setNodePosition,
	getRehabTree,
	saveRehabTree,
	runRehabTree,
	setCurrentTreeId,
	addRehabTreeValidationResult,
	resetFilters,
	setStartNodeId,
	setRefreshList,
	setNewTreeName,
	addNewRehabActionToTree,
	getRehabTreeStatus,
	getRehabTreeValidationStatus,
	clearScanId,
} from '@Actions';
import {
	selectCurrentTree,
	selectIsLoading,
	edgeConnector,
	selectRehabActions,
	selectTreeValidationResult,
	startNodeIdSelector,
	selectAddNewActionToTree,
	selectGetRehabTreeStatus,
	selectGetRehabTreeValidationStatus,
} from '@Selectors/Rehab.selectors';
import { useDispatch } from 'react-redux';
import { FullPageWrapper } from '@Utils/styles';
import { QueryDrawer, QueryFormValues, RenameRehab } from '@Components';
import ButtonEdge, { ReadOnlyCustomEdge } from '@Components/ButtonEdge';
import InnoInputNode from '@Components/ReactFlowNodes/InnoInputNode';
import { useStyles } from './DecisionTree.style';
import CanvasIcon from '@Components/Tools/CanvasIcon.svg';
import { useUnits } from '@innovyze/stylovyze';
import RehabActionList from '@Components/RehabActionList';
import { RehabActionDrawer } from '@Components/RehabActionDrawer';
import RehabResult from '@Components/RehabResult';
import ActionInputNode from '@Components/ReactFlowNodes/ActionInputNode';
import { fullAccess, viewerAccess } from '@innovyze/shared-utils';
import { InspectionStandard } from '@Types';
import {
	AssetDetailActions,
	InspDetailActions,
} from '@Components/RehabResult/RehabResult.component';
import { AssetDetails, InspDetails } from '@innovyze/lib_am_common/Components';
import { getStartNodeId, getAllNodes, TREE_STATES } from '@Utils/TreeUtils';
import { getLabelForDuplicate } from '@Utils/duplicateNodeName';
import { useGlobalization } from '@Translations';
import { SelectChangeEvent } from '@mui/material';
import { SystemTypes } from '@innovyze/inno-map';

const nodeWidth = 200;
const nodeHeight = 128;

export const DecisionTree = () => {
	const { t } = useGlobalization();
	const subscriptions = getSubscriptions();
	const hasFullAccess = fullAccess(subscriptions);
	const hasViewerAccess = viewerAccess(subscriptions);

	const history = useHistory();
	const dispatch = useDispatch();
	const params: { treeId: string } = useParams();
	const [currentTreeName, setCurrentTreeName] = useState('');
	const [isDirty, setIsDirty] = useState(false);
	const [existingTreeId, setExistingTreeId] = useState('');
	const edgeConnectorObject = edgeConnector();
	const classes = useStyles();
	const [reactFlowInstance, setReactFlowInstance] =
		useState<OnLoadParams | null>(null);
	const [elements, setElements] = useState<Elements>([]);
	const [nodeIdToEdit, setNodeIdToEdit] = useState<undefined | string>(
		undefined,
	);
	const isLoading = selectIsLoading();
	const [rehabActionToEdit, setRehabActionToEdit] = useState<
		undefined | null | RehabAction
	>(undefined);
	const [editName, setEditName] = useState(false);

	const [currentTab, setCurrentTab] = useState<number>(0);

	const [detailInspection, setDetailInspection] = useState<string[]>([
		'', // Video URL
		'', // Inspection ID
		'', // Standard
	]);
	const [detailAsset, setDetailAsset] = useState<string[]>(['', '']);

	const [addActionDialogOpen, setAddActionDialogOpen] = useState(false);
	const [addActionSelection, setAddActionSelection] = useState('');
	const [defectCodes, setDefectCodes] = useState<string[] | null>(null);
	const nodes: Node[] =
		(reactFlowInstance?.getElements().filter(el => isNode(el)) as Node[]) ??
		[];
	const [checkTreeStatusCounter, setCheckTreeStatusCounter] =
		useState<number>(0);
	const [ticker, setTicker] = useState<NodeJS.Timeout>();
	const currentTree = selectCurrentTree();
	const rehabTreeStatus = selectGetRehabTreeStatus();
	const rehabTreeValidationStatus = selectGetRehabTreeValidationStatus();
	const rehabTreeValidationResult = selectTreeValidationResult();
	const currentTreeStartNodeId = startNodeIdSelector();
	const [startNodeConnectedLimit, setStartNodeConnectedLimit] =
		useState(false);

	const addNewActionToTree = selectAddNewActionToTree();

	const isRehabTreeRunning = (): boolean => {
		return rehabTreeStatus === TREE_STATES.IN_PROGRESS;
	};

	const isRehabTreeValidating = (): boolean => {
		return rehabTreeValidationStatus === TREE_STATES.IN_PROGRESS;
	};

	const isRehabTreeEditable = (): boolean =>
		hasFullAccess && !isRehabTreeRunning() && !isRehabTreeValidating();

	// Translate the system + type into the STANDARD (!!!) asset type
	const assetType = useMemo(() => {
		switch (currentTree.systemType?.toLowerCase()) {
			case 'sanitary sewer':
				switch (currentTree.assetType?.toLowerCase()) {
					case 'manhole':
						return 'wwManhole';
					case 'pipe':
					default:
						return 'wwPipe';
				}
			case 'water distribution':
				switch (currentTree.assetType?.toLowerCase()) {
					case 'pipe':
					default:
						return 'pipe';
				}
		}
	}, [currentTree]);

	const systemType = useMemo(() => {
		switch (currentTree.systemType?.toLowerCase()) {
			case 'water distribution':
				return SystemTypes.WaterDistribution;
			case 'sanitary sewer':
			default:
				return SystemTypes.SanitarySewer;
		}
	}, [currentTree]);

	useEffect(() => {
		dispatch(setCurrentTreeId(params.treeId));
		setExistingTreeId('');
		if (params.treeId !== undefined && params.treeId != '') {
			setExistingTreeId(params.treeId);
			dispatch(
				getRehabTree({
					Id: params.treeId,
				}),
			);
			// refreshTreeRunStatus();
			dispatch(resetFilters());
			dispatch(
				addRehabTreeValidationResult({
					treeId: params.treeId,
					isValid: true,
					validationMessages: [],
				}),
			);

			// if (!hasViewerAccess) dispatch(getRehabTreesList());
		}
	}, []);

	useEffect(() => {
		if (edgeConnectorObject.id && edgeConnectorObject.delete)
			setIsDirty(true);
	}, [edgeConnectorObject]);

	useEffect(() => {
		setElements((els: any[]) => {
			const edgeToUpdate = els?.find(
				el => el.id === edgeConnectorObject.id,
			);
			if (edgeToUpdate) {
				if (edgeConnectorObject.delete == true) {
					setIsDirty(true);
					return els?.filter(
						edge => edge.id != edgeConnectorObject.id,
					);
				} else {
					return els?.map(el => {
						if (el.id == edgeConnectorObject.id) {
							return {
								...el,
								label: edgeConnectorObject.connectorCondition
									? 'True'
									: 'False',
							};
						}

						return el;
					});
				}
			} else {
				return els;
			}
		});
	}, [edgeConnectorObject]);

	const refreshTreeRunStatus = () => {
		dispatch(
			getRehabTreeStatus({
				Id: params.treeId,
			}),
		);
	};

	const refreshTreeValidationStatus = () => {
		dispatch(
			getRehabTreeValidationStatus({
				Id: params.treeId,
			}),
		);
	};

	useEffect(() => {
		if (isRehabTreeRunning()) {
			refreshTreeRunStatus();
			setTicker(
				setTimeout(() => {
					setCheckTreeStatusCounter(checkTreeStatusCounter + 1);
				}, 5000),
			);
		} else if (isRehabTreeValidating()) {
			refreshTreeValidationStatus();
			setTicker(
				setTimeout(() => {
					setCheckTreeStatusCounter(checkTreeStatusCounter + 1);
				}, 5000),
			);
		}
	}, [checkTreeStatusCounter]);

	useEffect(() => {
		if (!ticker && isRehabTreeRunning()) {
			setCheckTreeStatusCounter(1);
		} else if (ticker) {
			if (!isRehabTreeRunning()) {
				clearTimeout(ticker);
				setTicker(undefined);
				setCheckTreeStatusCounter(0);
			}
			if (rehabTreeStatus === TREE_STATES.COMPLETE) {
				dispatch(success(t('Tree run successful')));
			} else if (rehabTreeStatus === TREE_STATES.FAILED) {
				dispatch(error(t('Tree run failed')));
			}
		}
	}, [rehabTreeStatus]);

	useEffect(() => {
		if (!ticker && isRehabTreeValidating()) {
			setCheckTreeStatusCounter(1);
		} else if (ticker) {
			if (!isRehabTreeValidating()) {
				clearTimeout(ticker);
				setTicker(undefined);
				setCheckTreeStatusCounter(0);
			}
			if (rehabTreeValidationStatus === TREE_STATES.COMPLETE) {
				dispatch(
					getRehabTree({
						Id: params.treeId,
					}),
				);
				dispatch(success(t('Previewing asset count complete')));
			} else if (rehabTreeValidationStatus === TREE_STATES.FAILED) {
				dispatch(error(t('Previewing asset count has failed')));
			}
		}
	}, [rehabTreeValidationStatus]);

	useEffect(() => {
		if (addNewActionToTree) {
			if (currentTab == 0) {
				const reactFlowItem: ReactFlowSchema = {
					id: uuid.v4(),
					parentId: null,
					type: NODE_TYPES.action,
					label: addNewActionToTree.actionId,
					data: {
						label: addNewActionToTree.actionId as string,
						nodeData: {
							actionDefectCodes: defectCodes ?? [],
							actionId: addNewActionToTree._id,
							costUnit: addNewActionToTree.costUnit,
							treeId: addNewActionToTree.treeId,
							unit: addNewActionToTree.unit,
							unitCost: addNewActionToTree.unitCost,
						},
					},
				};
				addToTree(reactFlowItem);
			}
			dispatch(addNewRehabActionToTree(undefined));
		}
	}, [addNewActionToTree]);

	const handleNodeDuplicateClick = (nodeId: string) => {
		setElements(ele => {
			const nodeToDuplicate = ele.find(e => e.id === nodeId);
			if (nodeToDuplicate) {
				if (nodeToDuplicate.type == NODE_TYPES.query) {
					const newId = 'idx_' + new Date().getTime().toString();
					const newLabel = getLabelForDuplicate(
						nodeToDuplicate.data.label,
						ele,
					);
					const duplicateNode = {
						id: newId,
						type: NODE_TYPES.query,
						position: {
							x: 24,
							y: 24,
						},
						data: {
							...nodeToDuplicate.data,
							id: newId,
							label: newLabel,
							title: newLabel,
							nodeData: {
								...nodeToDuplicate.data.nodeData,
								title: newLabel,
								assetsCount: {},
							},
						},
					};
					setIsDirty(true);
					return [...ele, duplicateNode];
				} else if (nodeToDuplicate.type == NODE_TYPES.action) {
					const newId = 'idx_' + new Date().getTime().toString();
					const duplicateNode = {
						id: newId,
						type: NODE_TYPES.action,
						position: {
							x: 24,
							y: 24,
						},
						data: {
							...nodeToDuplicate.data,
							id: newId,
							nodeData: {
								...nodeToDuplicate.data.nodeData,
								assetsCount: {},
							},
						},
					};

					setIsDirty(true);
					return [...ele, duplicateNode];
				}
				return ele;
			} else {
				return ele;
			}
		});
	};

	const handleNodeDeleteClick = (nodeId: string) => {
		setElements(els => {
			const elementToRemove = els.find(el => el.id === nodeId);
			if (elementToRemove) {
				const allNodes = getAllNodes(els as any);
				if (allNodes.length === 2) {
					const startNodeId = getStartNodeId(els);
					const startElement = els.find(el => el.id === startNodeId);

					if (startElement)
						return removeElements(
							[startElement, elementToRemove],
							els,
						);
				}
				return removeElements([elementToRemove], els);
			} else {
				return els;
			}
		});
		setIsDirty(true);
	};

	const handleNodeEditClick = (nodeId: string) => {
		setAddActionSelection('');
		setNodeIdToEdit('');

		setNodeIdToEdit(nodeId);

		const currentTreeNode = currentTree.nodes.find(
			element => element.id == nodeId,
		);

		const elementsNode = elements.find(element => element.id == nodeId);

		if (currentTreeNode != undefined) {
			if (currentTreeNode.type == NODE_TYPES.query)
				dispatch(openDrawer('addQueryDrawer'));
			else if (elementsNode != undefined) {
				setAddActionSelection(
					elementsNode.data?.nodeData.actionId ?? '',
				);
				setAddActionDialogOpen(true);
			} else {
				setAddActionSelection(
					currentTreeNode.data?.nodeData.actionId ?? '',
				);
				setAddActionDialogOpen(true);
			}
		} else {
			if (elementsNode != undefined) {
				setAddActionSelection(
					elementsNode.data?.nodeData.actionId ?? '',
				);
				setAddActionDialogOpen(true);
			} else {
				const instanceNode = reactFlowInstance
					?.getElements()
					.find(element => element.id == nodeId);
				if (instanceNode != undefined) {
					if (instanceNode.type == NODE_TYPES.query) {
						dispatch(openDrawer('addQueryDrawer'));
					} else {
						setAddActionSelection(
							instanceNode.data?.nodeData.actionId ?? '',
						);
						setAddActionDialogOpen(true);
					}
				} else {
					setElements(ele => {
						const nodeToEdit = ele.find(e => e.id === nodeId);
						if (nodeToEdit) {
							if (nodeToEdit.type === NODE_TYPES.query) {
								dispatch(openDrawer('addQueryDrawer'));
							} else if (nodeToEdit.type === NODE_TYPES.action) {
								setAddActionSelection(
									nodeToEdit.data?.nodeData.actionId ?? '',
								);
								setAddActionDialogOpen(true);
							}
						}
						return ele;
					});
				}
			}
		}
	};

	useEffect(() => {
		if (
			currentTree != null &&
			currentTree._id === params.treeId &&
			currentTree?.nodes
		) {
			setCurrentTreeName(currentTree.name);
			let updatedNodes: (Node | Edge)[] = [];

			updatedNodes = currentTree.nodes?.map(node => {
				let updatedNode!: Node | Edge;
				if (node.id) {
					if (node.target && node.source) {
						updatedNode = {
							// ...node,
							id: node.id.toString(),
							label: node.label,
							source: node.source.toString(),
							target: node.target.toString(),
							type: 'buttonedge',
						};
					} else {
						let xy: XYPosition = { x: 24, y: 24 };
						if (node.position) {
							xy = {
								x: node.position.x,
								y: node.position.y,
							};
						}
						updatedNode = {
							id: node.id.toString(),
							type: node.type ? node.type : NODE_TYPES.query,
							style: node.style ? node.style : {},
							data: {
								id: node.id,
								label: node.data?.label,
								nodeData: node.data?.nodeData,
								title: node.data?.label,
								onEditClick: handleNodeEditClick,
								onDeleteClick: handleNodeDeleteClick,
								onDuplicateClick: handleNodeDuplicateClick,
							},
							position: xy,
						};
					}
				}
				return updatedNode;
			});

			setElements(updatedNodes);
		}
	}, [currentTree]);

	const handleSaveTree = () => {
		if (reactFlowInstance) {
			const allElements: Elements = reactFlowInstance.getElements();
			const currentNodes: Node[] = allElements.filter(element =>
				isNode(element),
			) as Node[];
			const currentEdges: Edge[] = allElements.filter(element =>
				isEdge(element),
			) as Edge[];
			currentNodes?.forEach(node => {
				const schema: ReactFlowSchema = {
					id: node.id,
					data: {
						label: node.data.label,
						nodeData: {
							...node.data.nodeData,
							title: node.data.label,
						},
					},
					style: node.style,
					type: node.type,
					position: node.position,
					label: node.data.label,
				};
				dispatch(addNodeToNewTree(schema));
			});
			currentEdges?.forEach(edge => {
				const schema: ReactFlowSchema = {
					id: edge.id,
					source: edge.source || '',
					target: edge.target || '',
					label: (edge?.label as string) || '',
					type: 'buttonedge',
				};
				dispatch(addNodeToNewTree(schema));
			});
			dispatch(
				setNewTreeName({
					name: currentTree.name,
					systemType: currentTree.systemType || 'Sanitary Sewer',
					assetType: currentTree.assetType || 'pipe',
					treeNodes: currentTree.nodes,
				}),
			);
			if (existingTreeId != '') {
				dispatch(saveRehabTree(existingTreeId));
			} else {
				dispatch(addRehabTree({ history: history }));
			}
			setIsDirty(false);
		}
	};

	const addQueryHandler = () => {
		setNodeIdToEdit('');
		dispatch(openDrawer('addQueryDrawer'));
	};

	const handleDrawerClose = () => {
		setNodeIdToEdit('');
		dispatch(closeDrawer());
	};

	const getQueryFormValues = (): QueryFormValues => {
		const defaultFormValues: QueryFormValues = {
			name: '',
			type: NODE_TYPES.query,
			rules: [],
		};
		const nodeToEdit: Node | undefined = nodes.find(
			el => el.id === nodeIdToEdit,
		);

		if (!nodeIdToEdit) {
			return defaultFormValues;
		}
		return {
			name: nodeToEdit?.data.label,
			type: NODE_TYPES.query,
			rules: nodeToEdit?.data.nodeData.rules,
		};
	};

	const validateRehabTreeHandler = (treeId: string) => {
		if (
			rehabTreeValidationResult.treeId === treeId &&
			!rehabTreeValidationResult.isValid
		) {
			dispatch(openDrawer('validationMessagesDrawer'));
		}
	};

	const runRehabTreeHandler = (treeId: string) => {
		if (
			rehabTreeValidationResult.treeId === treeId &&
			rehabTreeValidationResult.isValid
		) {
			dispatch(clearScanId());
			dispatch(runRehabTree({ treeId: treeId }));
		}
	};

	const getUpdatedElements = (
		currentElements: Elements,
		formValues: ReactFlowSchema,
	): Elements => {
		const currentNodes = currentElements.filter(element =>
			isNode(element),
		) as Node[];
		const currentEdges = currentElements.filter(element =>
			isEdge(element),
		) as Edge[];
		let updatedNodes: (Node | Edge)[] = [];
		if (nodeIdToEdit) {
			updatedNodes = currentNodes?.map(node => {
				const updatedNode: Node =
					node.id === nodeIdToEdit
						? {
								...node,
								data: {
									...node.data,
									...formValues.data,
									title: formValues.label,
								},
						  }
						: node;
				return updatedNode;
			});
		} else {
			// create a new node
			const getNewNodePosition = (): XYPosition => {
				const initialX = 24;
				const initialY = 24;
				if (!reactFlowInstance) {
					return { x: initialX, y: initialY };
				}
				const maxNodesPerRow = 5;
				const maxRowsToCheck = 5;

				let currentRowY = initialY;
				for (
					let currentRow = 0;
					currentRow < maxRowsToCheck;
					currentRow++
				) {
					let currentRowXs: number[] = [];
					if (currentElements) {
						currentRowXs = currentElements
							.filter(
								element =>
									isNode(element) &&
									element.position.y === currentRowY,
							)
							.map(element => (element as Node).position.x)
							.sort((n1, n2) => n1 - n2);
					}
					if (currentRowXs.length === 0) {
						return { x: initialX, y: currentRowY };
					} else if (currentRowXs.length < maxNodesPerRow) {
						return {
							x:
								currentRowXs[currentRowXs.length - 1] +
								nodeWidth +
								36,
							y: currentRowY,
						};
					}
					currentRowY += nodeHeight + 24;
				}
				return { x: initialX, y: initialY };
			};
			const nodeId = 'idx_' + new Date().getTime().toString();
			const newNode: Node = {
				id: nodeId,
				type: formValues.type ? formValues.type : NODE_TYPES.query,
				position: getNewNodePosition(),
				data: {
					id: nodeId,
					label: formValues.label,
					nodeData: formValues.data?.nodeData,
					title: formValues.label,
					onEditClick: handleNodeEditClick,
					onDeleteClick: handleNodeDeleteClick,
					onDuplicateClick: handleNodeDuplicateClick,
				},
			};
			updatedNodes = [...currentNodes, newNode];
		}

		updatedNodes = [...updatedNodes, ...currentEdges];

		return updatedNodes;
	};

	const addToTree = (values: ReactFlowSchema) => {
		if (!reactFlowInstance) {
			getUpdatedElements([], values);
			return;
		}
		setTimeout(() => {
			setNodeIdToEdit('');
		}, 1000);
		setIsDirty(true);
		const newElements = getUpdatedElements(
			reactFlowInstance.getElements(),
			values,
		);

		const startNodeId = getStartNodeId(newElements);
		if (newElements.length === 1 && !startNodeId) {
			const startNode = {
				id: 'idxStart_' + new Date().getTime().toString(),
				// Cant translate as the label is used for code :(
				data: { label: 'Start' },
				style: {
					border: '1px solid #5F60FF',
					padding: 10,
					background: '#5F60FF',
					borderRadius: '1rem',
					color: 'white',
					fontSize: '1.2rem',
				},
				type: 'input',
				position: { x: 700, y: 50 },
			};
			dispatch(setStartNodeId(startNode.id));
			newElements.push(startNode);
		}
		setElements(newElements);
	};

	const onLoad = (rfi: OnLoadParams) => {
		setReactFlowInstance(rfi);
	};

	const isLoop = (
		sourceId: string | null,
		targetId: string | null,
		allNodes: any[],
		allEdges: any[],
	): boolean => {
		if (sourceId == null || targetId == null) return false;
		const targetNode = allNodes.filter(
			node => (node as any).id == targetId,
		);
		let bIsLoop = false;
		if (targetNode != undefined) {
			const connectEdges = allEdges.filter(
				edge => (edge as any).source == targetId,
			);

			if (connectEdges != undefined && connectEdges.length > 0) {
				const foundLoop: any = connectEdges.filter(
					edge => (edge as any).target == sourceId,
				);
				if (foundLoop != undefined && foundLoop.length > 0) {
					bIsLoop = true;
					return bIsLoop;
				}

				for (const edge of connectEdges) {
					bIsLoop = isLoop(
						sourceId,
						(edge as any).target,
						allNodes,
						allEdges,
					);
					if (bIsLoop == true) {
						break;
					}
				}
			}
		}

		return bIsLoop;
	};

	const onConnect = (params: Connection | Edge) => {
		//check if start node connected to more than one node
		if (params.source == params.target) return;

		const existingStartNodeEdges: any[] = [];
		elements.forEach(elem => {
			if (
				(elem as any).source === params.source &&
				params.source === currentTreeStartNodeId
			) {
				existingStartNodeEdges.push(elem);
			}
		});
		for (const ele of elements) {
			if (
				(ele as any).id == params.source &&
				(ele as any).type == NODE_TYPES.action
			) {
				return;
			}
		}
		if (existingStartNodeEdges.length === 1) {
			setStartNodeConnectedLimit(true);
			return;
		} else {
			setStartNodeConnectedLimit(false);
		}

		const allEdges = elements.filter(
			el => (el as any).type == 'buttonedge',
		);
		const connetingToSameNode = allEdges.find(
			edge => (edge as any).target == params.target,
		);
		if (connetingToSameNode != null) {
			return;
		}
		const allNodes = elements.filter(
			el => (el as any).type != 'buttonedge',
		);
		if (isLoop(params.source, params.target, allNodes, allEdges)) return;

		const existingEdges: any[] = [];
		elements.forEach(elem => {
			if ((elem as any).source === params.source) {
				existingEdges.push(elem);
			}
		});
		let newLabel = '';
		if (existingEdges.length === 0) {
			newLabel = '';
		} else if (existingEdges.length === 1) {
			// This does a text comparison so have to compare to the translation
			const isTrue = existingEdges[0].label.indexOf('True') > -1;
			newLabel = isTrue ? 'False' : 'True';
		} else {
			return;
		}
		const newConnection: Edge = {
			id: params.source + '_' + params.target,
			source: params.source || '',
			target: params.target || '',
			arrowHeadType: ArrowHeadType.ArrowClosed,
			label: newLabel,
			type: 'buttonedge',
		};
		setElements(elements => elements.concat(newConnection));
		setIsDirty(true);
	};

	const handleElementsRemove = (elementsToRemove: Elements) => {
		setElements(els => {
			els.forEach(element => {
				elementsToRemove.find(el => isNode(el) && el.id === element.id);
			});
			return removeElements(elementsToRemove, els);
		});
	};

	const handleNodeDragStop = (event: ReactMouseEvent, node: Node) => {
		const posParams: NodePositionParams = {
			Id: node.id,
			position: node.position,
		};
		dispatch(setNodePosition(posParams));
		setIsDirty(true);
	};

	const nodeTypes = {
		innoInput: InnoInputNode,
		actionInput: ActionInputNode,
	};

	const edgeTypes = hasFullAccess
		? { buttonedge: ButtonEdge }
		: { buttonedge: ReadOnlyCustomEdge };

	const style = { width: '100%' };

	const ReactFlowCanvas = (
		<ReactFlowProvider>
			<div className="reactflow-wrapper">
				<ReactFlow
					nodesDraggable={isRehabTreeEditable()}
					nodesConnectable={isRehabTreeEditable()}
					elements={elements}
					onLoad={onLoad}
					onConnect={onConnect}
					onElementsRemove={handleElementsRemove}
					onNodeDragStop={handleNodeDragStop}
					nodeTypes={nodeTypes}
					edgeTypes={edgeTypes}
					connectionMode={ConnectionMode.Loose}
					hidden={elements.length < 1}
					style={style}>
					<Controls showInteractive={hasFullAccess} />
					<MiniMap
						nodeColor={node => {
							switch (node.type) {
								case 'input':
									return 'gold';
								case 'default':
									return '#00ff00';
								case 'output':
									return 'blue';
								default:
									return '#eee';
							}
						}}
						nodeStrokeWidth={1}
					/>
				</ReactFlow>
			</div>
		</ReactFlowProvider>
	);

	const rehabActions = selectRehabActions();

	useEffect(() => {
		if (
			elements == undefined ||
			elements.length == 0 ||
			rehabActions.length == 0
		)
			return;

		const newEle = elements.map(ele => {
			if (ele.type == NODE_TYPES.action) {
				const action = rehabActions.find(
					act => act._id == (ele.data as any).nodeData.actionId,
				);
				if (action != undefined) {
					return {
						...ele,
						data: {
							...ele.data,
							label: action.actionId,
							nodeData: {
								...ele.data.nodeData,
								title: action.actionId,
							},
							title: action.actionId,
						},
					};
				}
				return ele;
			} else {
				return ele;
			}
		});
		setElements(newEle);
	}, [rehabActions]);

	const addActionHandler = () => {
		setAddActionDialogOpen(true);
	};

	const addActionSelectChangeHandler = (event: SelectChangeEvent<string>) => {
		const target = event?.target as HTMLInputElement;
		if (target != undefined) {
			setAddActionSelection(target.value);
			const action = rehabActions.find(
				rehabaction => rehabaction._id == target.value,
			);
			if (action != null) {
				setDefectCodes(action.settings.defects);
			} else setDefectCodes(null);
		} else {
			setAddActionSelection('');
			setDefectCodes(null);
		}
	};

	const addActionDialogConfirmHandler = () => {
		setAddActionDialogOpen(false);

		if (addActionSelection != '') {
			if (addActionSelection === '-1') {
				dispatch(openDrawer('addNewActionDrawer'));
			} else {
				let id = '';
				const node = nodes.find(node => node.id == nodeIdToEdit);
				if (node != undefined) {
					id = node.id;
				} else {
					id = uuid.v4();
				}
				const action = rehabActions.find(
					rehabaction => rehabaction._id == addActionSelection,
				);
				const reactFlowItem: ReactFlowSchema = {
					id: id,
					parentId: null,
					type: NODE_TYPES.action,
					label: action?.actionId,
					data: {
						label: action?.actionId as string,
						nodeData: {
							actionDefectCodes: defectCodes ?? [],
							actionId: action?._id,
							costUnit: action?.costUnit,
							treeId: action?.treeId,
							unit: action?.unit,
							unitCost: action?.unitCost,
						},
					},
				};
				addToTree(reactFlowItem);
			}
		}

		setAddActionSelection('');
		setDefectCodes(null);
	};

	const PlaceholderForEmptyCanvas = (
		<div className={classes.canvasReactFlowEmpty}>
			<div className={classes.canvasReactFlowEmptyIcon}>
				<img src={CanvasIcon} alt={t('Decision Tree Icon')} />
			</div>
			<div className={classes.canvasReactFlowEmptyTitle}>
				<Typography gutterBottom variant="h6">
					{t('Your decision tree is empty')}
				</Typography>
			</div>

			{hasFullAccess ? (
				<div className={classes.canvasReactFlowEmptyNote}>
					<Typography align="center" gutterBottom variant="body1">
						{t('Use the “Add” buttons to begin')}
					</Typography>
					<Typography align="center" gutterBottom variant="body1">
						{t('constructing your tree.')}
					</Typography>
				</div>
			) : (
				[]
			)}

			{hasFullAccess ? (
				<div className={classes.canvasReactFlowEmptyActions}>
					<Button
						disabled={
							hasViewerAccess ||
							isLoading ||
							isRehabTreeRunning() ||
							isRehabTreeValidating()
						}
						variant="contained"
						className={classes.canvasReactFlowEmptyActionsQuery}
						startIcon={<Add />}
						onClick={addQueryHandler}>
						{t('Query')}
					</Button>

					<Button
						disabled={
							hasViewerAccess ||
							isLoading ||
							isRehabTreeRunning() ||
							isRehabTreeValidating()
						}
						variant="contained"
						startIcon={<Add />}
						onClick={addActionHandler}>
						{t('Action')}
					</Button>
				</div>
			) : (
				[]
			)}
			{ReactFlowCanvas}
		</div>
	);

	const addActionDialogContent = useMemo(() => {
		const node = elements.find(node => node.id == nodeIdToEdit);
		if (nodeIdToEdit != '' && node != undefined) {
			// 	setAddActionSelection(node.data.label);
			if (node.data.nodeData?.actionDefectCodes) {
				if (defectCodes == null) {
					setDefectCodes(node.data.nodeData.actionDefectCodes);
				}
			}
		}
		return (
			<Grid style={{ width: '300px' }}>
				<FormControl variant="outlined" margin="normal" fullWidth>
					<InputLabel id="action-type-id" variant="outlined">
						{t('Action Type')}
					</InputLabel>
					<Select
						labelId="action-type-id"
						value={addActionSelection}
						data-cy="actionChoiceList"
						name="action"
						onChange={addActionSelectChangeHandler}
						label={t('Action Type')}>
						{nodeIdToEdit == undefined || nodeIdToEdit == '' ? (
							<MenuItem key={'newAction'} value={'-1'}>
								{t('Add New Action')}
							</MenuItem>
						) : (
							[]
						)}
						{rehabActions?.map((action, index) => (
							<MenuItem
								key={index + '' + action._id}
								value={action._id}>
								{action.actionId}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		);
	}, [rehabActions, addActionSelection, defectCodes, nodeIdToEdit]);

	const treeDashboardTab = () => {
		return (
			<>
				<StylovyzeDialog
					dialogProps={{ maxWidth: 'lg' }}
					data-cy="add-action-dialog"
					open={addActionDialogOpen}
					title={
						nodeIdToEdit == undefined || nodeIdToEdit == ''
							? t('Add Action')
							: t('Edit Action')
					}
					content={addActionDialogContent}
					confirmText={
						nodeIdToEdit == undefined || nodeIdToEdit == ''
							? t('Add Action')
							: t('Save Action')
					}
					confirmButtonProps={{ color: 'primary' }}
					cancelText={t('Cancel')}
					onConfirm={addActionDialogConfirmHandler}
					onCancel={() => {
						setAddActionDialogOpen(false);
						setDefectCodes(null);
						setTimeout(() => {
							setNodeIdToEdit('');
						}, 500);
					}}
				/>
				{hasFullAccess ? (
					<div className={classes.treeActionsContainer}>
						<Grid
							container
							spacing={1}
							className={classes.treeActions}>
							<Grid item>
								<Button
									disabled={
										hasViewerAccess ||
										isLoading ||
										isRehabTreeRunning() ||
										isRehabTreeValidating()
									}
									variant="outlined"
									startIcon={<Add />}
									onClick={addQueryHandler}>
									{t('Query')}
								</Button>
							</Grid>
							<Grid item>
								<Button
									disabled={
										hasViewerAccess ||
										isLoading ||
										isRehabTreeRunning() ||
										isRehabTreeValidating()
									}
									variant="outlined"
									startIcon={<Add />}
									onClick={addActionHandler}>
									{t('Action')}
								</Button>
							</Grid>
						</Grid>

						<div className={classes.treeActionsRight}>
							{rehabTreeValidationResult.treeId ===
								params.treeId &&
							!rehabTreeValidationResult.isValid ? (
								<Grid
									item
									style={{
										alignSelf: 'center',
										color: '#AA0000',
									}}>
									{t('Validation Failed: ')}
									<Link
										component="button"
										variant="body2"
										color="inherit"
										className={classes.errorsButton}
										onClick={() =>
											validateRehabTreeHandler(
												params.treeId,
											)
										}>
										{t('View Errors')}
									</Link>
								</Grid>
							) : (
								<></>
							)}
							<Button
								variant="contained"
								className={classes.treeActionsRightRun}
								disabled={
									isDirty ||
									hasViewerAccess ||
									(rehabTreeValidationResult.treeId ===
										params.treeId &&
										!rehabTreeValidationResult.isValid) ||
									currentTree?.nodes?.length === 0 ||
									isRehabTreeRunning() ||
									isRehabTreeValidating()
								}
								data-cy="button-NewTree"
								color="primary"
								onClick={() =>
									runRehabTreeHandler(params.treeId)
								}>
								{isRehabTreeRunning() ? t('Running') : t('Run')}
							</Button>
							<Button
								variant="outlined"
								className={classes.treeActionsRightSave}
								color="secondary"
								onClick={handleSaveTree}
								disabled={
									!isDirty ||
									hasViewerAccess ||
									isRehabTreeRunning() ||
									isRehabTreeValidating()
								}>
								{isRehabTreeValidating()
									? t('Previewing')
									: t('Save')}
							</Button>
						</div>
					</div>
				) : (
					[]
				)}

				{elements.length == 0 && PlaceholderForEmptyCanvas}
				{elements.length > 0 && (
					<Grid
						container
						direction="column"
						alignItems="center"
						className={classes.canvasReactFlow}
						justifyContent="center">
						{ReactFlowCanvas}
					</Grid>
				)}
			</>
		);
	};

	const { system } = useUnits();

	const rehabActionTableCells: string[][] = [];
	if (rehabActions) {
		for (const rehabAction of rehabActions) {
			if (rehabAction) {
				let unitCost = rehabAction.unitCost;
				let unit = rehabAction.unit?.toString();
				if (rehabAction.unit == CostUnit.length) {
					if (system === 'Metric') {
						unit = t('/m (length)');
					} else {
						unitCost = unitCost / 3.28084;
						unit = t('/ft (length)');
					}
				}
				rehabActionTableCells.push([
					rehabAction.actionId,
					unitCost != undefined
						? unitCost.toFixed(2).toString()
						: '0',
					unit,
					rehabAction.notes,
					'',
				]);
			}
		}
	}

	const onClickAway = useCallback(() => {
		setDetailInspection(['', '', '']);
		setDetailAsset(['', '']);
	}, []);

	const onInspDetailClicked = useCallback(
		(
			videoURL: string,
			inspectionID: string,
			standard: InspectionStandard,
		) => {
			setDetailInspection([videoURL, inspectionID, standard.toString()]);
		},
		[],
	);

	const inspDetailActions: InspDetailActions = {
		onInspectionDetailClicked: onInspDetailClicked,
	};

	const onAssetDetailClicked = useCallback(
		(assetId: string, assetType: string) => {
			setDetailAsset([assetId, assetType]);
		},
		[],
	);

	const assetDetailActions: AssetDetailActions = {
		onAssetDetailClicked: onAssetDetailClicked,
	};

	const showDetailInspection = () => {
		return (
			detailInspection &&
			detailInspection.length == 3 &&
			detailInspection[1]
		);
	};

	const showDetailAsset = () => {
		return (
			detailAsset &&
			detailAsset.length == 2 &&
			detailAsset[1] &&
			!showDetailInspection()
		);
	};

	const rehabResultTab = () => {
		return (
			<RehabResult
				viewerAccess={hasViewerAccess}
				treeId={params.treeId}
				assetAction={assetDetailActions}
				assetType={assetType ?? 'wwPipe'}
			/>
		);
	};

	const rehabActionTab = () => {
		return (
			<RehabActionList
				viewerAccess={hasViewerAccess}
				isLoading={isLoading}
				actionTableCells={rehabActionTableCells}
				onRehabActionChange={setRehabActionToEdit}
				nodes={nodes}
			/>
		);
	};

	const detInspection = () => {
		return showDetailInspection() ? (
			<Paper
				style={{
					width: '100%',
					height: '100%',
					top: '0%',
					left: '0%',
					position: 'relative',
				}}>
				<InspDetails
					// videoUrl={detailInspection[0]}
					back={{
						action: () => {
							setDetailInspection(['', '', '']);
						},
						label: t('Back to Asset Details'),
					}}
					inspectionId={detailInspection[1]}
					standard={detailInspection[2] as InspectionStandard}
				/>
			</Paper>
		) : (
			[]
		);
	};

	const detAsset = () => {
		return showDetailAsset() ? (
			<Paper
				style={{
					width: '100%',
					height: '100%',
					top: '0%',
					left: '0%',
					position: 'relative',
				}}>
				<AssetDetails
					assetId={detailAsset[0]}
					back={{
						action: onClickAway,
						label: t('Back to Rehab Results'),
					}}
					assetType={detailAsset[1]}
					actions={inspDetailActions}
					adminFullAccess={hasFullAccess}
					systemType={systemType}
				/>
			</Paper>
		) : (
			[]
		);
	};
	const detInsp = useMemo(() => detInspection(), [detailInspection]);
	const detAss = useMemo(() => detAsset(), [detailAsset, detailInspection]);

	const secondaryNav = () => {
		const tabs = [
			{ content: treeDashboardTab(), title: t('Configuration') },
			{ content: rehabResultTab(), title: t('Results') },
			{
				content: rehabActionTab(),
				title: t('Actions'),
			},
		];
		return tabs;
	};

	const itemIndexChanged = (itemIndex: number) => {
		setCurrentTab(itemIndex);
	};

	const back = () => {
		history.push('/rehab/');
	};

	const onEditName = () => {
		setEditName(true);
	};

	const onEditNameClose = (saved: boolean, newName: string) => {
		setEditName(false);
		if (saved) {
			dispatch(setRefreshList(true));
			setCurrentTreeName(newName);
		}
	};

	return (
		<>
			<RenameRehab
				open={editName}
				treeId={existingTreeId}
				name={currentTreeName}
				onClose={onEditNameClose}
			/>
			<NavigationPrompt
				when={isDirty && hasFullAccess}
				navigate={nextLocation => {
					history.push(nextLocation);
				}}
				confirmText={t('Discard')}
				shouldBlockNavigation={() => true}
				shouldBlockReload={isDirty}
				content={t(
					'You have modified your tree configuration and have unsaved changes. You can discard changes, or cancel to continue editing.',
				)}></NavigationPrompt>
			<RehabActionDrawer
				treeId={params.treeId}
				viewerAccess={hasViewerAccess}
				rehabAction={rehabActionToEdit}></RehabActionDrawer>
			{detInsp}
			{detAss}
			{!showDetailInspection() && !showDetailAsset() ? (
				<FullPageWrapper
					className={isLoading ? 'wait' : ''}
					isLoading={isLoading && !currentTree.name}
					title={currentTreeName}
					minorHeader={
						!hasViewerAccess && currentTreeName.length != 0 ? (
							<div
								style={{
									paddingLeft: '20px',
									paddingRight: '20px',
								}}>
								<IconButton
									title={t('Edit Name')}
									data-cy="editButton"
									onClick={onEditName}>
									<Create />
								</IconButton>
							</div>
						) : (
							<></>
						)
					}
					applyPadding={true}
					headerUnderline
					secondaryNav={{
						items: secondaryNav(),
						itemIndex: currentTab,
						itemIndexChanged: itemIndexChanged,
					}}
					back={{
						action: back,
						label: t('Back to Rehab Decision Tree'),
					}}>
					{currentTree.name ? (
						<QueryDrawer
							isNew={
								nodeIdToEdit == undefined || nodeIdToEdit == ''
									? true
									: false
							}
							queryValues={getQueryFormValues()}
							allNodes={elements}
							onAddToTree={addToTree}
							currentTree={currentTree}
							onDrawerClose={handleDrawerClose}></QueryDrawer>
					) : (
						<></>
					)}
					<Drawer
						drawerId="validationMessagesDrawer"
						onCancel={() => dispatch(closeDrawer())}
						title={t('Validation Errors')}>
						{rehabTreeValidationResult?.validationMessages?.map(
							(message, index) => {
								return (
									<div key={index}>
										<Text variant={'heading-medium'}>
											{t('ERROR: {{message}}', {
												message: message.errorMessage,
											})}
										</Text>
										<br></br>
									</div>
								);
							},
						)}
					</Drawer>
				</FullPageWrapper>
			) : (
				[]
			)}
			<WarningDialog
				open={startNodeConnectedLimit}
				title={t('The Main Tree Connection')}
				content={t(
					'You cannot have two main trees. You should connect the start node with only one node.',
				)}
				cancelText={t('Cancel')}
				//confirmText={'confirm'}
				onCancel={() => setStartNodeConnectedLimit(false)}
				onConfirm={() => setStartNodeConnectedLimit(false)}
			/>
		</>
	);
};

export default DecisionTree;
