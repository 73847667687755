import { useEffect, useState } from 'react';
import { ObjectShape } from 'yup/lib/object';
import * as yup from 'yup';
import { FIELDS_TYPES } from '../../utils/choiceListMapping';
import { TFunc } from '@Translations/types';

const extendYupSchema = (
	validationSchemaNew: yup.ObjectSchema<ObjectShape>,
	valueType: string,
	t: TFunc,
): yup.ObjectSchema<ObjectShape> => {
	switch (valueType) {
		case FIELDS_TYPES.BOOLEAN:
			return validationSchemaNew.shape({
				value1: yup.string().required(t(t('Value is required'))),
			});
		case FIELDS_TYPES.TEXT_ONE_FIELD:
			return validationSchemaNew.shape({
				value1: yup.string().required(t('Value is required')),
			});
		case FIELDS_TYPES.NUMERICAL_ONE_FIELD:
			return validationSchemaNew.shape({
				value1: yup
					.number()
					.required(t('Value is required'))
					.min(0, t('Value must be greater than or equal to zero')),
				unitLength: yup
					.number()
					.nullable()
					.notRequired()
					.when('field', {
						is: (field: string) => {
							if (field && field === 'DEFECT_COUNT_UNIT_LENGTH')
								return true;
						},
						then: yup
							.number()
							.typeError(() => {
								return t('Value is required');
							})
							.required(t('Value is required'))
							.min(1, t('Value must be greater than zero')),
						otherwise: yup.number().nullable().notRequired(),
					}),
			});
		case FIELDS_TYPES.NUMERICAL_TWO_FIELDS:
			return validationSchemaNew.shape({
				value1: yup
					.number()
					.required(t('Value 1 is required'))
					.typeError(t('Invalid value'))
					.min(0, t('Value must be greater than or equal to zero')),
				value2: yup.number().when('value1', {
					is: (value1: any) =>
						value1 !== undefined &&
						value1 !== '' &&
						value1 !== null,
					then: yup
						.number()
						.nullable()
						.required(t('Value 2 is required'))
						.typeError(t('Invalid value'))
						.test(
							'is-greater',
							t('Value 2 should be greater than Value 1'),
							function (value: any) {
								const { value1 } = this.parent;
								return value > value1;
							},
						),
					otherwise: yup
						.number()
						.nullable()
						.required(t('Value is required'))
						.typeError(t('Invalid value'))
						.min(
							0,
							t('Value must be greater than or equal to zero'),
						),
				}),
				unitLength: yup
					.number()
					.nullable()
					.notRequired()
					.when('field', {
						is: (field: string) => {
							if (field && field === 'DEFECT_COUNT_UNIT_LENGTH')
								return true;
						},
						then: yup
							.number()
							.typeError(() => {
								return t('Value is required');
							})
							.required(t('Value is required'))
							.min(1, t('Value must be greater than zero')),
						otherwise: yup.number().nullable().notRequired(),
					}),
			});
		case FIELDS_TYPES.NUMERICAL_UNRESTRICTED_ONE_FIELD:
			return validationSchemaNew.shape({
				value1: yup.number().required(t('Value is required')),
			});
		case FIELDS_TYPES.NUMERICAL_UNRESTRICTED_TWO_FIELDS:
			return validationSchemaNew.shape({
				value1: yup
					.number()
					.required(t('Value 1 is required'))
					.typeError(t('Invalid value')),
				value2: yup.number().when('value1', {
					is: (value1: any) =>
						value1 !== undefined &&
						value1 !== '' &&
						value1 !== null,
					then: yup
						.number()
						.nullable()
						.typeError(t('Invalid value'))
						.required(t('Value 2 is required'))
						.test(
							'is-greater',
							t('Value 2 should be greater than Value 1'),
							function (value: any) {
								const { value1 } = this.parent;
								return value > value1;
							},
						)
						.nullable(),
					otherwise: yup.number().required(t('Value 2 is required')),
				}),
			});
		case FIELDS_TYPES.MULTI_SELECT_ONE_FIELD:
			return validationSchemaNew.shape({
				value1: yup
					.array()
					.required(t('You should select at least 1 element'))
					.min(1, t('You should select at least 1 element')),
			});
		case FIELDS_TYPES.DATE_ONE_FIELD:
			return validationSchemaNew.shape({
				value1: yup
					.date()
					.typeError(t('Invalid date'))
					.required(t('Date is required')),
			});
		case FIELDS_TYPES.DATE_TWO_FIELDS:
			return validationSchemaNew.shape({
				value1: yup
					.date()
					.typeError(t('Invalid date'))
					.required(t('Date From is required')),
				value2: yup.date().when('value1', {
					is: (value1: any) =>
						value1 !== undefined &&
						value1 !== '' &&
						value1 !== null,
					then: yup
						.date()
						.typeError(t('Invalid date'))
						.nullable()
						.test(
							'is-greater',
							t('Date To should be greater than Date From'),
							function (value: any) {
								const { value1 } = this.parent;
								return (
									new Date(value).getTime() >
									new Date(value1).getTime()
								);
							},
						)
						.nullable(),
					otherwise: yup
						.date()
						.typeError(t('Invalid date'))
						.required(t('Date To is required')),
				}),
			});
		case FIELDS_TYPES.DATE_YEAR_ONE_FIELD:
			return validationSchemaNew.shape({
				value1: yup
					.date()
					.typeError(t('Invalid date'))
					.required(t('Year is required'))
					.test(
						'is-greater',
						t('Date should be greater than 1900'),
						function (value: any) {
							return value && value.getFullYear() >= 1900;
						},
					)
					.test(
						'is-less',
						t('Date should be less than 2100'),
						function (value: any) {
							return value && value.getFullYear() < 2100;
						},
					),
			});
		case FIELDS_TYPES.DATE_YEAR_TWO_FIELDS:
			return validationSchemaNew.shape({
				value1: yup
					.date()
					.typeError(t('Invalid date'))
					.required(t('Year From is required'))
					.test(
						'is-greater',
						t('Year should be greater than 1900'),
						function (value: any) {
							return value && value.getFullYear() >= 1900;
						},
					)
					.test(
						'is-less',
						t('Year should be less than 2100'),
						function (value: any) {
							return value && value.getFullYear() < 2100;
						},
					),
				value2: yup.date().when('value1', {
					is: (value1: any) =>
						value1 !== undefined &&
						value1 !== '' &&
						value1 !== null,
					then: yup
						.date()
						.typeError(t('Invalid date'))
						.required(t('Year To is required'))
						.nullable()
						.test(
							'is-greater',
							t('Year To should be greater than Year From'),
							function (value: any) {
								const { value1 } = this.parent;
								return (
									value &&
									value1 &&
									value.getTime() > value1.getTime()
								);
							},
						)
						.test(
							'is-greater',
							t('Year should be greater than 1900'),
							function (value: any) {
								return value && value.getFullYear() >= 1900;
							},
						)
						.test(
							'is-less',
							t('Year should be less than 2100'),
							function (value: any) {
								return value && value.getFullYear() < 2100;
							},
						)
						.nullable(),
					otherwise: yup
						.date()
						.typeError(t('Invalid date'))
						.required(t('Year To is required')),
				}),
			});

		case FIELDS_TYPES.DAYS_SINCE:
			return validationSchemaNew.shape({
				value1: yup
					.number()
					.required(t('Value is required'))
					.min(1, t('Value must be greater than or equal to 1')),
			});
		case FIELDS_TYPES.YEARS_SINCE:
			return validationSchemaNew.shape({
				value1: yup
					.number()
					.required(t('Value is required'))
					.min(1, t('Value must be greater than or equal to 1')),
			});
		case FIELDS_TYPES.PERCENTAGE_TEXT:
			return validationSchemaNew.shape({
				value1: yup
					.number()
					.required(t('Value is required'))
					.min(0, t('Value must be greater than or equal to zero'))
					.max(100, t('Value must be less than 100')),
			});
		case FIELDS_TYPES.PERCENTAGE_TWO_FIELDS:
			return validationSchemaNew.shape({
				value1: yup
					.number()
					.required(t('Value is required'))
					.min(0, t('Value must be greater than or equal to zero'))
					.max(100, t('Value must be less than 100')),
				value2: yup
					.number()
					.required(t('Value is required'))
					.min(0, t('Value must be greater than or equal to zero'))
					.max(100, t('Value must be less than 100')),
			});
		default:
			return validationSchemaNew;
	}
};

//
const useYupSchemaCreate: any = (t: TFunc): any => {
	const [validationSchema, setValidationSchema] = useState<
		yup.ObjectSchema<ObjectShape>
	>(
		yup.object().shape({
			recordType: yup.string().required(),
			field: yup.string().required(),
			operator: yup.string().required(),
		}),
	);
	const [yupValueType, setYupValueType] = useState('');

	useEffect(() => {
		if (!yupValueType) return;

		const validationSchemaNew = yup.object().shape({
			recordType: yup.string().required(),
			field: yup.string().required(),
			operator: yup.string().required(),
		});
		const newYupSchema: yup.ObjectSchema<ObjectShape> = extendYupSchema(
			validationSchemaNew,
			yupValueType,
			t,
		);
		setValidationSchema(newYupSchema);
	}, [yupValueType]);

	return [validationSchema, setYupValueType];
};

export default useYupSchemaCreate;
