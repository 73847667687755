import { RECORD_TYPE, RECORD_TYPE_ENDPOINT } from './choiceListMapping';
import * as _ from 'lodash-es';
import {
	RehabQueryChoiceList,
	RehabRuleChoiceListFiles,
	Rules,
} from '@Types/rehab.types';

export interface ChoiceListPairs {
	label: string;
	value: string;
}

interface RecordFile {
	[index: string]: any;
}
const getChoiceListObj = (choiceListRuleList: RehabQueryChoiceList[]) => {
	if (choiceListRuleList && choiceListRuleList.length > 0)
		return _.groupBy(choiceListRuleList, 'key');
	else return {};
};

const getConfigFields = (
	recordTypeChoiceListData: RehabQueryChoiceList[],
	config: string,
	key: string,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	recordType: string,
) => {
	try {
		const recordTypeFieldsData: RehabQueryChoiceList = _.groupBy(
			recordTypeChoiceListData,
			key,
		);
		return recordTypeFieldsData[config][0]['fields'];
	} catch (e) {
		return [];
	}
};

const getSpecificRecordFile = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	recordType: string,
	config?: string,
): RecordFile | null => {
	switch (recordType) {
		case RECORD_TYPE.ASSET_ATTRIBUTES:
			return getChoiceListObj(recordTypeChoiceListFiles.assetRecordType);
		case RECORD_TYPE.INSPECTION:
			return getChoiceListObj(
				recordTypeChoiceListFiles.inspectionRecordType,
			);
		case RECORD_TYPE.RISK: {
			const configStr = config ? config : '';
			const fieldsData = getConfigFields(
				recordTypeChoiceListFiles.riskRecordType,
				configStr,
				'configId',
				RECORD_TYPE.RISK,
			);
			return getChoiceListObj(fieldsData);
		}
		case RECORD_TYPE.CONDITION:
			return getChoiceListObj(
				recordTypeChoiceListFiles.conditionRecordType,
			);
		case RECORD_TYPE.DEFECT_FILTER:
			return getChoiceListObj(
				recordTypeChoiceListFiles.defectFilterRecordType,
			);
		case RECORD_TYPE.USER_DEFINED_FIELDS: {
			const configStr = config ? config : '';
			const fieldsData = getConfigFields(
				recordTypeChoiceListFiles.udtRecordType,
				configStr,
				'tableName',
				RECORD_TYPE.USER_DEFINED_FIELDS,
			);
			return getChoiceListObj(fieldsData);
		}
		case RECORD_TYPE.TASK: {
			const configStr = config ? config : '';
			const fieldsData = getConfigFields(
				recordTypeChoiceListFiles.tasksRecordType,
				configStr,
				'uploadId',
				RECORD_TYPE.TASK,
			);
			return getChoiceListObj(fieldsData);
		}
		default:
			return null;
	}
};

export const createChoiceListFromList = (data: string[]): ChoiceListPairs[] => {
	const choiceListPairs: ChoiceListPairs[] = [];
	data.forEach((ele: string) => {
		choiceListPairs.push({
			label: ele,
			value: ele,
		});
	});
	return choiceListPairs;
};

export const getAllFields = (choiceListObj: any): ChoiceListPairs[] => {
	return createChoiceListFromList(Object.keys(choiceListObj));
};

export const getChoiceListFromSpecificFile = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	recordType: string,
	config?: string,
): ChoiceListPairs[] => {
	const recordFile: RecordFile | null = getSpecificRecordFile(
		recordTypeChoiceListFiles,
		recordType,
		config,
	);

	const allFields = getAllFields(recordFile);

	allFields.map((field: ChoiceListPairs) => {
		if (recordFile && recordFile[field.value]) {
			field.label = recordFile[field.value][0].display_name;
		}
		if (!field.label) {
			field.label = field.value;
		}
	});

	return allFields;
};

export const getFieldsData = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	recordType: string,
	config?: string,
) => {
	const recordFile: RecordFile | null = getSpecificRecordFile(
		recordTypeChoiceListFiles,
		recordType,
		config,
	);

	return recordFile;
};

export const getAllOperatorsForSpecificField = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	recordType: string,
	field: string,
	config?: string,
): string[] => {
	//inputAsset[field]
	const recordFile: RecordFile | null = getSpecificRecordFile(
		recordTypeChoiceListFiles,
		recordType,
		config,
	);
	let operatorMenuItems: string[] = [];
	if (recordFile && recordFile[field]) {
		operatorMenuItems = Object.keys(recordFile[field][0]['operators']);
	}

	return operatorMenuItems;
};

export const getIsDisabledForSpecificField = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	recordType: string,
	field: string,
	config?: string,
): boolean => {
	const recordFile: RecordFile | null = getSpecificRecordFile(
		recordTypeChoiceListFiles,
		recordType,
		config,
	);
	let isDisabled = false;
	if (recordFile && recordFile[field]) {
		isDisabled = recordFile[field][0]['fieldDisabled'];
	}
	return isDisabled;
};

export const getValueTypeForSpecificOperator = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	recordType: string,
	field: string,
	operator: string,
	config?: string,
): string => {
	const recordFile: RecordFile | null = getSpecificRecordFile(
		recordTypeChoiceListFiles,
		recordType,
		config,
	);
	if (recordFile && recordFile[field])
		return recordFile[field][0]['operators'][operator]['valueType'];

	return '';
};

export const getUnitStatus = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	recordType: string,
	field: string,
	config?: string,
): boolean => {
	const recordFile: RecordFile | null = getSpecificRecordFile(
		recordTypeChoiceListFiles,
		recordType,
		config,
	);
	if (recordFile && recordFile[field] && recordFile[field][0]) {
		if (recordFile[field][0]['unit']) return true;
	}
	return false;
};

export const getRecordTypeEndPoint = (recordType: string): string => {
	switch (recordType) {
		case RECORD_TYPE.ASSET_ATTRIBUTES:
			return RECORD_TYPE_ENDPOINT.ASSET_ATTRIBUTES;
		case RECORD_TYPE.INSPECTION:
			return RECORD_TYPE_ENDPOINT.INSPECTION;
		case RECORD_TYPE.RISK:
			return RECORD_TYPE_ENDPOINT.RISK;
		case RECORD_TYPE.CONDITION:
			return RECORD_TYPE_ENDPOINT.CONDITION;
		default:
			return '';
	}
};

export const getFieldOptions = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	recordType: string,
	fieldName: string,
	config?: string,
	// inspectionStandard?: string,
) => {
	let fieldData: any = {};
	let fieldOptions = [];

	if (
		recordTypeChoiceListFiles &&
		recordTypeChoiceListFiles.assetRecordType &&
		recordTypeChoiceListFiles.assetRecordType.length > 0
	) {
		fieldData = getSpecificRecordFile(
			recordTypeChoiceListFiles,
			recordType,
			config,
		);

		fieldOptions =
			fieldData[fieldName] && fieldData[fieldName][0]
				? fieldData[fieldName][0].fieldOptions
				: [];
	}
	let fieldOptionsResult = [];

	if (fieldOptions && fieldOptions.length > 0) {
		if (recordType == RECORD_TYPE.INSPECTION) {
			fieldOptionsResult = fieldOptions.map((fieldOption: any) => {
				return {
					label: fieldOption['display_name'],
					value: fieldOption['value'],
				};
			});
		} else {
			fieldOptionsResult = fieldOptions.map((fieldOption: any) => {
				return { label: fieldOption, value: fieldOption };
			});
		}
	}

	return fieldOptionsResult;
};

export const initMultiSelectFieldForEdit = (
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
	initValues: Rules,
) => {
	let fieldData: any = {};
	let fieldOptions = [];
	let fieldOptionsResult: any = [];

	if (recordTypeChoiceListFiles && Array.isArray(initValues.value1)) {
		fieldData = getSpecificRecordFile(
			recordTypeChoiceListFiles,
			initValues.recordType as string,
			initValues.config as string,
		);
		fieldOptions = fieldData[initValues.field as string]?.[0]
			? fieldData[initValues.field as string][0].fieldOptions
			: [];

		const fieldOptionsObj = _.groupBy(fieldOptions, 'value');
		fieldOptionsResult = initValues.value1.map((value: string) => {
			const fieldOptionValues = fieldOptionsObj[value][0];

			return {
				label: fieldOptionValues['display_name'],
				value: fieldOptionValues['value'],
			};
		});
	}

	return fieldOptionsResult;
};
