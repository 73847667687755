import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
	createStyles({
		edgeRoot: {
			'&:hover': {
				'& $hoverIcon': {
					display: 'initial',
				},
			},
		},
		hoverIcon: {
			display: 'none',
		},
	}),
);
