import { Rules } from '@Types/rehab.types';
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { SortableGroupedRuleItem } from './SortableGroupedRuleItem';
interface Props {
	rule: Rules;
	classes: any;
	ruleIdToCreateGrouped: string;
	updateRuleCondition: (rule: Rules) => void;
	updateGroupRuleCondition: (rule: Rules, groupedRule: Rules) => void;
	setRuleIdToCreateGrouped: (id: string) => void;
	onEditRule: (id: string, groupedId: string | null) => void;
	onDeleteRule: (id: string, groupedId: string | null) => void;
	handleConditionClickedForGroupedRule: (
		condition: string,
		ruleId: string,
	) => void;
	setQueryPanelDisabledState: (state: boolean) => void;
	isQueryPanelDisabled: boolean;
}
export const SortableGroupedRuleList = SortableContainer(
	({
		rule,
		classes,
		ruleIdToCreateGrouped,
		updateRuleCondition,
		updateGroupRuleCondition,
		setRuleIdToCreateGrouped,
		onEditRule,
		onDeleteRule,
		handleConditionClickedForGroupedRule,
		setQueryPanelDisabledState,
		isQueryPanelDisabled,
	}: Props) => {
		return (
			<div>
				{rule.groupedRules &&
					rule.groupedRules.length > 0 &&
					rule.groupedRules.map((groupedRule: Rules, index) => (
						<SortableGroupedRuleItem
							key={`grouped-${groupedRule.id}`}
							index={index}
							rule={rule}
							groupedRule={groupedRule}
							classes={classes}
							ruleIdToCreateGrouped={ruleIdToCreateGrouped}
							updateRuleCondition={updateRuleCondition}
							updateGroupRuleCondition={updateGroupRuleCondition}
							setRuleIdToCreateGrouped={setRuleIdToCreateGrouped}
							onEditRule={onEditRule}
							onDeleteRule={onDeleteRule}
							handleConditionClickedForGroupedRule={
								handleConditionClickedForGroupedRule
							}
							setQueryPanelDisabledState={
								setQueryPanelDisabledState
							}
							isQueryPanelDisabled={isQueryPanelDisabled}
						/>
					))}
			</div>
		);
	},
);

export default SortableGroupedRuleList;
