/*eslint-disable */
import { createGlobalStyle } from "styled-components";

export const DatePickerOverridesStyles = createGlobalStyle<{
}>`

	.react-datepicker-popper {
		z-index: 99;
		font-family: 'Open Sans', sans-serif;
		font-size: 1.6rem;
		min-width: 25rem;
	}

	.react-datepicker {
		font-family: inherit;
		font-size: inherit;
		width: 100%;
		border-color: #DDDDDD;
		display: flex;
		box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.15);
		//border-radius: 1rem;
	}

	.react-datepicker__month-container {
		width: 100%;
		float: none;
		
	}

	.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        width: 3rem;
		font-size: 1.2rem;
	}

	.react-datepicker__day {
		padding: .8rem;
		color:#484C50;
	}

	.react-datepicker__day-names {
		//display: flex;
    	//justify-content: space-evenly;
		color: #768D95;


		opacity: 0.5;
		margin: .5rem 0;
	}
	.react-datepicker__day--disabled{
		color: #768D95;
		opacity: 0.5;
	}


	.react-datepicker__day-names {
		margin-top: 1rem;
	}

	.react-datepicker__navigation {
		border-width: .8rem;
		top: 22px;
	}

	.react-datepicker__navigation--previous {
		border-right-color: #768D95 ;
	
		
		&:hover {
			border-right-color:#333333 ;
		}
	}

	.react-datepicker__navigation--next {
		border-left-color: #768D95 ;
	//	left: 220px;

		&:hover {
			border-left-color: #333333;
		}
	}

	.react-datepicker__current-month {
		font-size: 1.6rem;
		font-weight: 500;
		color: #333333;
		margin-top: 1rem;
	}

	.react-datepicker__day{

	&:hover {
			border-radius: 50%;

		}
}

	.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range,.react-datepicker__day--keyboard-selected {
		background-color: #007CA0;
		border-radius: 50%;
		font-weight: 500;
		
	}
.react-datepicker__day--selected,.react-datepicker__day--keyboard-selected{
	color: #FFFFFF;
	&:hover {
			background-color: #007CA0;
			color: #FFFFFF;
			border-radius: 50%;

		}
}


	.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
		border-bottom-color: #DDDDDD;
	}

	.react-datepicker__header {
		background-color: #fff;
		border-bottom: 0;
	}

	.react-datepicker__day--outside-month {
		color:#DDDDDD ;
	}

	.react-datepicker__time-container  {
		float: none;
		border-color:#DDDDDD;
	}

	.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
		font-size: 1.2rem;
		padding: 0;
		height: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.react-datepicker-time__header {
		font-size: 1.6rem;
		font-weight: 500;
		color: #333333;
	}

	.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
		background-color:#007CA0;
		font-weight: 400;
	}

	.react-datepicker__day--today {
		background-color: white;
		color:#333333 ;
		border: solid 1px #007CA0;
		border-radius: 50%;

		&:hover {
			background-color: #007CA0;
			color: #FFFFFF;
			border-radius: 50%;

		}
	}

`;
